import React, { useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';


export const DialogChangePass = ({ open, handleClose, authTokens }) => {

    const [newPass, setNewPass] = useState('');

    const handleChangePass = () => {
        fetch('fetch_changePass', {
            method: 'DELETE',
            body: JSON.stringify({ usuario: authTokens.usuario, pass: newPass }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(result => {
                if (result.message) {
                    alert(result.message)
                    handleClose();
                } else {
                    alert(result.status)
                }

            });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle id="form-dialog-title">Cambio de Contraseña</DialogTitle>
            <DialogContent style={{padding:10}}>
                <TextField
                    value={newPass || ''}
                    onChange={(e) => setNewPass(e.target.value)}
                    fullWidth
                    size='small'
                    variant='outlined'
                    label='Clave Nueva'
                    required
                    type='password'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="error" variant="outlined">
                    Cancelar
                </Button>
                <Button onClick={handleChangePass} color="primary" variant="contained">
                    Cambiar
                </Button>
            </DialogActions>
        </Dialog>
    )
}