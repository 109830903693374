import React from "react";
import {
  Navigate,
} from "react-router-dom";


const ReportsListComponent = React.lazy(() => import("../../../components/reports/components/list/page"));
const ReportsCreateComponent = React.lazy(() => import("../../../components/reports/components/add/page"));


export const reportsRoute = [
  {
    path: 'list',
    element: <ReportsListComponent />,
  },
  {
    path: 'add',
    element: <ReportsCreateComponent />,
  },
  {
    path: 'edit',
    element: <Navigate to="/reports/add" />
  },
  {
    path: 'edit/:id',
    element: <ReportsCreateComponent />
  },
  {
    path: '',
    element: <Navigate replace to="/reports/list" />
  },
]