// import React from "react";
import { GenericPage } from "../../../../../pages";

import { bankRoute } from "./models-bank.route";
import { catalogueRoute } from "./models-catalogue.route";
import { moduleRoute } from "./models-module.route";
import { optionRoute } from "./models-options.route";
import { taskRoute } from "./models-task.route";
import { groupRoute } from "./models-groups.route";



export const modelsRoute = [
  {
    path: 'banks',
    element: <GenericPage />,
    children:bankRoute,
  },
  {
    path: 'catalogues',
    element: <GenericPage />,
    children:catalogueRoute,
  },
  {
    path: 'modules',
    element: <GenericPage />,
    children:moduleRoute,
  },
  {
    path: 'options',
    element: <GenericPage />,
    children:optionRoute,
  },
  {
    path: 'tasks',
    element: <GenericPage />,
    children:taskRoute,
  },
  {
    path: 'groups',
    element: <GenericPage />,
    children:groupRoute,
  },
]