import React, { lazy } from "react";
import {
    Navigate,
} from "react-router-dom";


const CronListComponent = lazy(() => import("../../../../../components/crons/components/list/page"));
const CronAddComponent = lazy(() => import("../../../../../components/crons/components/add/page"));

export const cronsRoute = [
    {
        path: 'list',
        element: <CronListComponent />
    },
    {
        path: 'add',
        element: <CronAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/options/crons/add" />
    },
    {
        path: 'edit/:id',
        element: <CronAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/options/crons/list" />
    },
];