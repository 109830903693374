import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Paper,
  TextField,
} from "@mui/material";
import { Button, Image, Tag } from "antd";
import { EquipmentAcI, EquipmentComputerI } from "../../../../libs/interfaces";

import noImage from "../../../../images/no-image.png";
import bgImage from "../../../images/19366.jpg";
import { Fragment, useEffect, useState } from "react";
import { Affix, Badge, Collapse, Descriptions } from "antd";
import type { CollapseProps, DescriptionsProps } from "antd";
import { v4 as uuidV4 } from "uuid";
import { useWidth } from "../../../../libs/hooks";
import { EquipmentTableConsumables } from "./consumables";
import { EquipmentTableOts } from "./ots";
import {
  AppstoreOutlined,
  BarsOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import {
  CustomDescriptions,
  CustomDescriptionItem,
} from "../../../../libs/shared/components/libs/description/description";

const arrNot = [
  "brandId",
  "typeEquipmentId",
  "typeEquipment",
  "typeEquipmentCode",
  "client",
  "clientId",
  "supplierId",
  "photos",
  "name",
  "typeAcId",
  "capabilitieId",
  "consumables",
  "ots",
  "clientAddress",
  "clientAddressId",
  "statusEquipmentId",
  "clientContactId",
  "id",
  "condensador",
  "evaporador"
];

function formatDate(dateString: string) {
  const date = new Date(dateString);

  // Opciones de formato
  const options = { year: "numeric", month: "long", day: "numeric" } as any;

  // Formateando la fecha
  const formattedDate = date.toLocaleDateString("es-ES", options);

  return formattedDate.toLocaleUpperCase();
}

const objTransform = {
  brand: "MARCA",
  model: "MODELO",
  location: "UBICACIÓN",
  lastMaintenanceDate: "ÚLTIMO MANTENIMIENTO",
  purchaseDate: "FECHA DE COMPRA",
  observation: "OBSERVACIÓN",
  supplier: "PROVEEDOR",
  sn: "N° SERIE",
  system: "SISTEMA",
  warranty: "GARANTÍA",
  timeWarranty: "TIEMPO DE GARANTÍA",
  timeWarrantyOption: "...",
  typeAc: "TIPO",
  condensador: "CONDENSADOR",
  capabilitie: "CAPACIDAD",
  evaporador: "EVAPORADOR",
  statusEquipment: "STATUS",
  nextMaintenanceDate: "PRÓX. MANT.",
  clientContact: "CONTACTO",
  periodValue: "PERIÓDO DE MANTENIMIENTOS",
  periodOption: "...",
  code: "UNIQUE ID",
} as any;

const objTransformSmall = {
  brand: "MARCA",
  model: "MODELO",
  location: "UBICACIÓN",
  lastMaintenanceDate: "ÚLT. MANT.",
  purchaseDate: "FECHA DE COMPRA",
  observation: "OBSERVACIÓN",
  supplier: "PROVEEDOR",
  sn: "N° SERIE",
  system: "SISTEMA",
  warranty: "GARANTÍA",
  timeWarranty: "TIEMPO",
  timeWarrantyOption: "...",
  typeAc: "TIPO",
  condensador: "CONDENSADOR",
  capabilitie: "CAPACIDAD",
  evaporador: "EVAPORADOR",
  statusEquipment: "STATUS",
  nextMaintenanceDate: "PRÓX. MANT.",
  clientContact: "CONTACTO",
  periodValue: "PER. DE MANT.",
  periodOption: "...",
  code: "UNIQUE ID",
} as any;

export const ModalBodyAc = ({
  data,
  interior,
}: {
  data: EquipmentComputerI;
  interior?: Boolean;
}) => {
  const width = useWidth();
  const [items, setItems] = useState<CustomDescriptionItem[]>([]);

  useEffect(() => {
    const newArr: CustomDescriptionItem[] = [];

    const entries = Object.entries(data)
      .filter(([key]) => !arrNot.includes(key)) // Filtra campos que contienen "Id"
      .map(([key, value]) => {
        const obj: CustomDescriptionItem = {
          key,
          label: width === "xs" ? objTransformSmall[key]: objTransform[key],
          content: value?.toString() || "N/A", // Convierte el valor a string
          noShow: false,
        };

        switch (key) {
          case "code":
            obj.order = 1;
            obj.fullWidth = false;
            break;
          case "brand":
            obj.order = 2;
            obj.fullWidth = false;
            break;
          case "model":
            obj.order = 3;
            obj.fullWidth = false;
            break;
          case "sn":
            obj.order = 4;
            obj.fullWidth = false;
            break;
          case "typeAc":
            obj.order = 5;
            obj.fullWidth = false;
            break;
          case "capabilitie":
            obj.order = 6;
            obj.fullWidth = false;
            break;
          case "purchaseDate":
            obj.order = 9;
            obj.fullWidth = false;
            obj.content = value?.toString() ? formatDate(value) : "";
            break;
          case "statusEquipment":
            obj.order = 10;
            obj.fullWidth = false;
            break;
          case "supplier":
            obj.order = 11;
            obj.fullWidth = false;
            break;
          case "clientContact":
            obj.order = 12;
            obj.fullWidth = false;
            break;
          case "warranty":
            obj.content =
              value?.toString() === "false" ? "No Tiene" : "Si Tiene";
            obj.order = 13;
            obj.fullWidth = false;
            break;
          case "location":
            obj.order = 14;
            obj.fullWidth = false;
            break;
          case "timeWarranty":
            obj.order = 15;
            obj.fullWidth = false;
            break;
          case "timeWarrantyOption":
            obj.order = 16;
            obj.fullWidth = false;
            break;
          case "lastMaintenanceDate":
            obj.order = 17;
            obj.fullWidth = false;
            obj.content = value?.toString() ? formatDate(value) : "";
            break;
          case "nextMaintenanceDate":
            obj.order = 18;
            obj.fullWidth = false;
            obj.content = value?.toString() ? formatDate(value) : "";
            break;
          case "periodValue":
            obj.fullWidth = false;
            obj.order = 19;
            break;
          case "periodOption":
            obj.fullWidth = false;
            obj.order = 20;
            break;
          case "observation":
            obj.fullWidth = true;
            obj.order = 21;
            break;
        }

        return obj;
      });

    for (const x of entries) {

      if (x.key === "timeWarranty") {
        const findW = entries.find((y) => y.key === "warranty");
        const findW1 = entries.find((y) => y.key === "timeWarrantyOption");
        if (findW?.content === "No Tiene") {
          x.noShow = true;
        }else{
          x.content = `${x.content} ${findW1?.content}`;
        }
      }

      if (x.key === "timeWarrantyOption") {
        const findW = entries.find((y) => y.key === "warranty");
        if (findW?.content === "No Tiene") {
          x.noShow = true;
        }
      }

      if (x.key === "periodValue") {
        const findW = entries.find((y) => y.key === "periodOption");
          x.content = `${x.content} ${findW?.content}`;
      }

      if (x.key === "periodOption") 
          x.noShow = true;

        

      newArr.push(x);
    }

    setItems(newArr);
  }, []);

  const CollapseOne = () => (
    <Fragment>
      <Affix offsetTop={83}>
        <Tag color="#87d068" style={{ marginLeft: 1 }}>
          {data.name}
        </Tag>
      </Affix>
      <CustomDescriptions items={items} columns={2}/>
    </Fragment>
  );

  const itemCollapse: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          type="dashed"
          style={{ position: "relative", top: -2 }}
          icon={<BarsOutlined />}
        >
          Información
        </Button>
      ),
      children: <CollapseOne />,
    },
    {
      key: "2",
      label: (
        <Button
          type="dashed"
          style={{ position: "relative", top: -2 }}
          icon={<ScheduleOutlined />}
        >
          Consumibles
        </Button>
      ),
      children: <EquipmentTableConsumables data={data.consumables} />,
    },
    {
      key: "3",
      label: (
        <Button
          type="dashed"
          style={{ position: "relative", top: -2 }}
          icon={<AppstoreOutlined />}
        >
          Órdenes de Trabajo
        </Button>
      ),
      children: <EquipmentTableOts data={data.ots} />,
    },
  ];

  return (
    <Grid
      container
      spacing={1}
      sx={{
        overflowY: "auto",
        backgroundImage: `linear-gradient(rgba(100%, 100%, 100%, 0.8), rgba(100%, 100%, 100%, 0.8)), url(${bgImage})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          minHeight: width === "xs" ? "auto" : "calc(100vh - 120px)",
          height: width === "xs" ? "auto" : "calc(100vh - 120px)",
          overflowY: "auto",
        }}
      >
        <Collapse
          items={itemCollapse}
          defaultActiveKey={["1"]}
          bordered={false}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ display: "grid", placeContent: "center" }}
      >
        <ImageList
          sx={{
            width: "100%",
            minWidth: 450,
            height: `calc(100vh - 120px)`,
            minHeight: `calc(100vh - 120px)`,
            border: "1px dashed gainsboro",
            background: "white",
          }}
        >
          {data.photos.map((item, index) => {
            const cols = index === 0 ? 2 : 1;
            const rows = index === 0 ? 2 : 1;

            return (
              <ImageListItem key={item.uuid} cols={cols} rows={rows}>
                <Image
                  width="100%"
                  style={{ maxHeight: 300 }}
                  src={item.src ?? noImage}
                  fallback={noImage}
                />
                <ImageListItemBar
                  title={`Foto ${item.order}`}
                  subtitle={<Tag color="#108ee9">{item.observation ?? ""}</Tag>}
                  position="below"
                  // actionIcon={
                  //     <IconButton
                  //         sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  //         aria-label={`info about ${item.title}`}
                  //     >
                  //         <InfoIcon />
                  //     </IconButton>
                  // }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Grid>
    </Grid>
  );
};
