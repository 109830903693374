import { useQuery } from "@tanstack/react-query";
import { moduleOptionApi } from "./axios";
import {
    // DataI,
    // MigrationsI,
    // PropsI,
    ServiceResponseI,
    ModuleOptionI,
    PresentationI,
    OptionI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


export const getAllOptionByModuleMatch = async (match:string):Promise<ServiceResponseI<OptionI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('match', match.toString());

        const { data } = await moduleOptionApi.get<ServiceResponseI<OptionI[]>>(
            `/all-option-by-module-match`, 
            {   params, 
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllOptionByModuleId = async (id:number):Promise<ServiceResponseI<OptionI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await moduleOptionApi.get<ServiceResponseI<OptionI[]>>(
            `/all-option-by-module-id/${id}`, 
            {   
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}




export const moduleOptionPost = async<T>(getForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await moduleOptionApi.post("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const moduleOptionPut = async<T>(id: number, updateForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await moduleOptionApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const moduleOptionbyId = async (id: number): Promise<ServiceResponseI<ModuleOptionI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await moduleOptionApi.get<ServiceResponseI<ModuleOptionI[]>>(`/all-by-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}