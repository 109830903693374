import React from "react";
import {
    Navigate,
} from "react-router-dom";

const PoloListComponent = React.lazy(() => import("../../../../../components/polo/components/list/page"));
const PoloAddComponent = React.lazy(() => import("../../../../../components/polo/components/add/page"));

export const poloRoute = [
    {
        path: 'list',
        element: <PoloListComponent />
    },
    {
        path: 'add',
        element: <PoloAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/store/polos/add" />
    },
    {
        path: 'edit/:id',
        element: <PoloAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/store/polos/list" />
    },
];