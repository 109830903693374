import { create, StateCreator } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer"

interface QuoterItemsDetailsState {
    id: number;
    idRow: string;
    quoteId: number;
    quoteItemId: number;
    match: string;
    groudId: number;
    quantity: number;
    primaryTotal: number;
    total: number;
    secondaryQuantity: number;
    secondaryTotal: number;
}

interface QuoterItemsState {

    id: number;
    idRow: string;
    quoteId: number;
    proformItemId: number;
    price: number;
    priceNeto: number;
    subtotal: number;
    discount: number;
    discountPercentage: string;
    discountTotal: string;
    quoterItemsDetails: QuoterItemsDetailsState[];
}

interface QuoterState {
    project: string;
    client: string;
    pdf?: string;
    proformId: number;
    id: number;
    subtotal: number;
    subtotalNeto: number;
    discount: number;
    total: number;
    totalInitial: number;
    iva: number;
    quoterItems: QuoterItemsState[];
}

interface QuoterActionsI {
    updateQuoterItems: (id: number, data: QuoterItemsState) => void;
    setDataQuoter:(allData:QuoterState)=> void;
}

const quoterStoreApi: StateCreator<QuoterState & QuoterActionsI, [["zustand/devtools", never], ["zustand/immer", never]]> =
    (set, get) => ({
        project: "",
        client: "",
        pdf: "",
        proformId: 0,
        id: 0,
        subtotal: 0,
        subtotalNeto: 0,
        discount: 0,
        total: 0,
        totalInitial: 0,
        iva: 0,
        quoterItems: [],

        setDataQuoter:(allData)=>{
            set({...allData});
        },

        updateQuoterItems: (id, data) => {
            console.log({ id, data })
        }

    });


export const useQuoterStore = create<QuoterState & QuoterActionsI>()(
    devtools(
        immer(
            quoterStoreApi
        )
    )
)