import { useQuery } from "@tanstack/react-query";
import { clientesApi } from "./axios";
import { 
    DataI,
    MigrationsI, 
    PropsI, 
    ServiceResponseI,
    ClientListI,
    ClientI,
    ProfileI,
    ObjPostI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';
import { CreateClientDataI } from "../../../components/clients/interface";

const getAllClients = async ({
    pagination, 
    sorting, 
    globalFilter,
    subscription
}:PropsI):Promise<ServiceResponseI<DataI<ClientListI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));
        subscription && params.append('subscription', JSON.stringify(subscription));
        
        //headers: { 'Authorization': validateToken
        const { data } = await clientesApi.get<ServiceResponseI<DataI<ClientListI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            
            LogoutSystem();
        }

        throw new Error((err as Error).message)
    }
}

export const getAllClientsNoPagination = async ():Promise<ServiceResponseI<ClientI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await clientesApi.get<ServiceResponseI<ClientI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const clientsPost = async<T>(getForms: T): Promise<ServiceResponseI<ObjPostI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientesApi.post("/", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientsPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientesApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getProfileIdByClientId = async (id: number): Promise<ServiceResponseI<ProfileI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientesApi.get<ServiceResponseI<ProfileI>>(`/get-profile-id-by-client-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}
export const clientById = async (id: number): Promise<ServiceResponseI<ClientI | CreateClientDataI | any>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientesApi.get<ServiceResponseI<ClientI | CreateClientDataI | any>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useClientesAll = ({
    sorting,
    pagination,
    globalFilter,
    subscription
}:PropsI)=>{

    
    const clientesQueryAll = useQuery(
        ["clientes-all", { pagination, sorting, globalFilter, subscription
         }],
        ()=>getAllClients({ pagination, sorting, globalFilter, subscription
         }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );


    return {
        clientesQueryAll,
    };
}