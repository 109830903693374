import { useQuery } from "@tanstack/react-query";
import { reportApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsReportI,
    ReportI,
    ObjPostI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getReports = async ({
    pagination, 
    sorting, 
    globalFilter,
    client,
    supplier,
    equipment,
    ot,
}:PropsReportI):Promise<ServiceResponseI<DataI<ReportI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        client && params.append('client', client.toString());
        supplier && params.append('supplier', supplier.toString());
        equipment && params.append('equipment', equipment.toString());
        ot && params.append('report', ot.toString());

        //headers: { 'Authorization': validateToken
        const { data } = await reportApi.get<ServiceResponseI<DataI<ReportI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            
            LogoutSystem();
        }

        throw new Error((err as Error).message)
    }

    
}

export const getAllReports = async ():Promise<ServiceResponseI<ReportI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await reportApi.get<ServiceResponseI<ReportI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const reportPost = async<T>(getForms: T): Promise<ServiceResponseI<ObjPostI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await reportApi.post<ServiceResponseI<ObjPostI>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const reportPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await reportApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const reportById = async (id: number): Promise<ServiceResponseI<ReportI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await reportApi.get<ServiceResponseI<ReportI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const reportDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await reportApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useReports = ({
    sorting,
    pagination,
    globalFilter,
    client,
    supplier,
    equipment,
    ot,
}:PropsReportI)=>{

    
    const reportsQuery = useQuery(
        ["reports", { pagination, sorting, globalFilter, client, supplier, equipment, ot }],
        ()=>getReports({ pagination, sorting, globalFilter, client, supplier, equipment, ot }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        reportsQuery,
    };
}