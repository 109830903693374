import { Navigate } from "react-router-dom";

export const ValidateCurrentToken = ()=>{
    try{ 
        const getToken = localStorage.getItem(process.env.REACT_APP_TOKEN!);

        if(!getToken) return <Navigate to="/login" />
    
        const { token } = JSON.parse(getToken)
        return token;
    }catch(err){
        console.log(err);
        <Navigate to="/login" />
    }

} 