import React from "react";
import {
    Navigate,
} from "react-router-dom";

const CatalogueListComponent = React.lazy(() => import("../../../../../components/catalogue/components/list/page"));
const CatalogueAddComponent = React.lazy(() => import("../../../../../components/catalogue/components/add/page"));

export const catalogueRoute = [
    {
        path: 'list',
        element: <CatalogueListComponent />
    },
    {
        path: 'add',
        element: <CatalogueAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/store/catalogues/add" />
    },
    {
        path: 'edit/:id',
        element: <CatalogueAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/store/catalogues/list" />
    },
];