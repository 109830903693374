import { useQuery } from "@tanstack/react-query";
import { groupApi } from "./axios";
import {
    DataI,
    ServiceResponseI,
    GroupI,
    PropsGroupsI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';

const getAllGroups = async ({
    pagination,
    sorting,
    globalFilter,
}: PropsGroupsI): Promise<ServiceResponseI<DataI<GroupI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));

        //headers: { 'Authorization': validateToken
        const { data } = await groupApi.get<ServiceResponseI<DataI<GroupI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            
            LogoutSystem();
        }

        throw new Error((err as Error).message)
    }


}

export const getAllGroupsNoPagination = async () => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await groupApi.get("/all-no-pagination", { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
    }
}


    export const groupsPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await groupApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }

    export const groupsPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await groupApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }

    export const groupsDelete = async(id: number): Promise<ServiceResponseI<string>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await groupApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }

    export const groupById = async (id: number): Promise<ServiceResponseI<GroupI>> => {
        try {
            const validateToken = ValidateCurrentToken();
            if (!validateToken) throw new Error('TOKEN INVALIDO');
            const { data } = await groupApi.get<ServiceResponseI<GroupI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
            return data;
        } catch (err) {
            const obj = (err as AxiosError).response;
            if (obj?.status === 401) {
                
                LogoutSystem();
            }
            throw new Error((obj?.data as Error).message)
        }
    }

    export const useGroups = ({
        sorting,
        pagination,
        globalFilter,
    }: PropsGroupsI) => {


        const groupQuery = useQuery(
            ["groups", { pagination, sorting, globalFilter}],
            () => getAllGroups({ pagination, sorting, globalFilter}),
            {
                refetchOnWindowFocus: false,
                keepPreviousData: true
            }
        );






        return {
            groupQuery,
        };
    }