import { useState  } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Drawer, DrawerHeader } from '../components/drawer/drawer';
import { Box, CssBaseline } from '@mui/material';
import { AppBar } from '../components/appbar/appbar';
import { Main } from '../components/main/main';
import { AuthContext } from '../../contexts';
import { useWidth } from '../../libs/hooks';

export const Layout = () => {
  const location = useLocation();
  const width = useWidth();
  const [open, setOpen] = useState(false);
  const [numproCrono, setNumproCrono] = useState('');
  const [headerWord, setHeaderWord] = useState('...');

  const tokens = localStorage.getItem(process.env.REACT_APP_TOKEN);
  const existingTokens = tokens ? JSON.parse(tokens) : null;

  const [authTokens, setAuthTokens] = useState(existingTokens);


  const setTokens = (data) => {
    localStorage.setItem(process.env.REACT_APP_TOKEN, JSON.stringify(data));
    setAuthTokens(data);
  };

  if (!tokens)
    return <Navigate to="/login" state={{ from: location }} replace />;

  const handleDrawerOpen = () => {
    setOpen(true);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  return (
    
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AuthContext.Provider value={{
          authTokens,
          headerWord,
          numproCrono,
          setAuthTokens: setTokens,
          setHeaderWord,
          setNumproCrono,
        }}>
        <AppBar
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          authTokens={existingTokens}
          headerWord=""
        />
        <Drawer open={open} handleClose={handleDrawerClose} />
        <Main open={open} width={width}> 
          <DrawerHeader /> 

            <Outlet />
        </Main>
        </AuthContext.Provider>
      </Box>

  )
};