import React, { ChangeEvent, useState } from "react";
import {
    Button,
    Chip,
    Grid,
    Stack,
    TextField
} from "@mui/material";
import { useSnackbar } from 'notistack';
import {
    createCatalogueChild,
} from "../../../../libs/apis"; 
import { 
    CreateCatalogueChildI,
    ModalGenericCreationI, 
} from "../../../../libs/interfaces";


export const CreateCatalogueChild = ({
    title,
    refetch,
    onClose,
    parentCode,
    placeholderRef='',
    placeholderNameRef,
}:ModalGenericCreationI) => {

    const { enqueueSnackbar } = useSnackbar();
    const [ presentation, setPresentation ] = useState({
        name:"",
        description:"",
        reference:"",
    });

    const handleSave = async () => {
        try {
            const obj = {
                ...presentation,
                parentCode,
            } as CreateCatalogueChildI
            const { data } = await createCatalogueChild(obj);
            handleClose();
            enqueueSnackbar(data, { variant: "success" });
            refetch();
        } catch (err: any) {
            console.log(err);
            enqueueSnackbar(err.toString(), { variant: "error" })
        }
    };

    const handleClose = () => {
        setPresentation({ name: "", description:"", reference:"" });
        onClose();
    };

    return (
        <Grid container spacing={1}>
            <Grid item sx={{mb:2}}>
                <Chip   
                    label={title}
                    color="secondary"
                    />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Nombre"
                    variant="outlined"
                    size="small"
                    name="name"
                    fullWidth
                    placeholder={placeholderNameRef}
                    value={presentation.name || ""}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => { setPresentation({...presentation, name: e.target.value.toUpperCase() }) }}
                    onBlur={(e) => { setPresentation({...presentation, name: e.target.value.trim() }) }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Descripción"
                    variant="outlined"
                    size="small"
                    name="description"
                    fullWidth
                    value={presentation.description || ""}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => { setPresentation({...presentation, description: e.target.value }) }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Referencia"
                    variant="outlined"
                    size="small"
                    name="reference"
                    fullWidth
                    value={presentation.reference || ""}
                    placeholder={placeholderRef}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => { setPresentation({...presentation, reference: e.target.value }) }}
                />
            </Grid>
            <Grid item xs={12} sx={{mt:2}}>
                <Stack direction="row" gap={1} justifyContent="flex-end">
                    <Button variant="outlined" color="error" size="small" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="success" onClick={handleSave} size="small">
                        Crear
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    )
}