import React from "react";
import {
    Navigate,
} from "react-router-dom";

const AmperageListComponent = React.lazy(() => import("../../../../../components/amperage/components/list/page"));
const AmperageAddComponent = React.lazy(() => import("../../../../../components/amperage/components/add/page"));

export const amperageRoute = [
    {
        path: 'list',
        element: <AmperageListComponent />
    },
    {
        path: 'add',
        element: <AmperageAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/store/amperages/add" />
    },
    {
        path: 'edit/:id',
        element: <AmperageAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/store/amperages/list" />
    },
];