import React from "react";
import {
  Navigate,
} from "react-router-dom";


const OtsListComponent = React.lazy(() => import("../../../components/ots/components/list/page"));
const OtsCreateComponent = React.lazy(() => import("../../../components/ots/components/add/page"));


export const otsRoute = [
  {
    path: 'list',
    element: <OtsListComponent />,
  },
  {
    path: 'add',
    element: <OtsCreateComponent />,
  },
  {
    path: 'edit',
    element: <Navigate to="/ots/add" />
  },
  {
    path: 'edit/:id',
    element: <OtsCreateComponent />
  },
  {
    path: '',
    element: <Navigate replace to="/ots/list" />
  },
]