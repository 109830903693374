export const KEY_CRYPTO: string = process.env.REACT_APP_KEY_CRYPTO!;
export const IV_CRYPTO: string = process.env.REACT_APP_IV_CRYPTO!;
export const CRYPTO_DATA: string = process.env.REACT_APP_CRYPTO_DATA!;
export const TYPE_MATERIAL_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_CODE!;
export const CATALOGUE_STATUS_CLIENT_CODE: string = process.env.REACT_APP_CATALOGUE_STATUS_CLIENT_CODE!;
export const TYPE_IDENTIFICATION_CODE: string = process.env.REACT_APP_TYPE_IDENTIFICATION_CODE!;
export const TYPE_MATERIAL_LINE_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_LINE_CODE!;
export const TYPE_PAYMENT_CODE: string = process.env.REACT_APP_TYPE_PAYMENT_CODE!; 
export const METHOD_PAYMENT_CODE: string = process.env.REACT_APP_METHOD_PAYMENT_CODE!;
export const TYPE_MATERIAL_SUPPLIER_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_SUPPLIER_CODE!;
export const TYPE_MATERIAL_GROUP_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_GROUP_CODE!;
export const TYPE_MATERIAL_MATERIAL_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_MATERIAL_CODE!;
export const CATALOGUE_MATERIAL_CATEGORY_CODE: string = process.env.REACT_APP_CATALOGUE_MATERIAL_CATEGORY_CODE!;
export const CATALOGUE_MATERIAL_PRESENTATION_CODE: string = process.env.REACT_APP_CATALOGUE_MATERIAL_PRESENTATION_CODE!;
export const CATALOGUE_MATERIAL_COLOR_CODE: string = process.env.REACT_APP_CATALOGUE_MATERIAL_COLOR_CODE!;
export const CATALOGUE_TYPE_CONTACT_CLIENT_CODE: string = process.env.REACT_APP_CATALOGUE_TYPE_CONTACT_CLIENT_CODE!;
export const CATALOGUE_TYPE_USER_EXECUTIVE_CODE: string = process.env.REACT_APP_CATALOGUE_TYPE_USER_EXECUTIVE_CODE!;
export const TYPE_ACCOUNT_BANK_CODE: string = process.env.REACT_APP_TYPE_ACCOUNT_BANK_CODE!;
export const CONTACT_TYPES_MAIL_CODE: string = process.env.REACT_APP_CONTACT_TYPES_MAIL_CODE!;
export const CONTACT_TYPES_PHONE_CODE: string = process.env.REACT_APP_CONTACT_TYPES_PHONE_CODE!;
export const CC_TYPE_RETENCION_CODE: string = process.env.REACT_APP_CC_TYPE_RETENCION_CODE!;
export const CC_TYPE_FACTURA_CODE: string = process.env.REACT_APP_CC_TYPE_FACTURA_CODE!;
export const CC_TYPE_COBRANZAS_CODE: string = process.env.REACT_APP_CC_TYPE_COBRANZAS_CODE!;
export const COLLECTION_TYPES_CODE: string = process.env.REACT_APP_COLLECTION_TYPES_CODE!;
export const WEEK_DAYS_CODE: string = process.env.REACT_APP_WEEK_DAYS_CODE!;
export const UNITS_MEASUREMENT_CODE: string = process.env.REACT_APP_UNITS_MEASUREMENT_CODE!;
export const CHARACTERISTICS_MATERIALS_CODE: string = process.env.REACT_APP_CHARACTERISTICS_MATERIALS_CODE!;
export const PINTURA_LINE_CODE: string = process.env.REACT_APP_PINTURA_LINE_CODE!;
export const STATUS_PROFORM_SEND_PRODUCTION_CODE: string = process.env.REACT_APP_STATUS_PROFORM_SEND_PRODUCTION_CODE!;
export const STATUS_PROFORM_ISSUED_CODE: string = process.env.REACT_APP_STATUS_PROFORM_ISSUED_CODE!;
export const STATUS_PROFORM_PENDING_CODE: string = process.env.REACT_APP_STATUS_PROFORM_PENDING_CODE!;
export const SUBSCRIPTION_TYPES_CODE: string = process.env.REACT_APP_SUBSCRIPTION_TYPES_CODE!;
export const STATUS_PROJECT_CODE: string = process.env.REACT_APP_STATUS_PROJECT_CODE!;
export const TYPE_USER_EJECUTIVA_CODE: string = process.env.REACT_APP_TYPE_USER_EJECUTIVA_CODE!;
export const TYPE_USER_GERENTE_CODE: string = process.env.REACT_APP_TYPE_USER_GERENTE_CODE!;
export const TYPE_USER_ADMINISTRADOR_CODE: string = process.env.REACT_APP_TYPE_USER_ADMINISTRADOR_CODE!;
export const TYPE_USER_SUPERVISOR_CODE: string = process.env.REACT_APP_TYPE_USER_SUPERVISOR_CODE!;
export const PROFILE_TYPES_CODE: string = process.env.REACT_APP_PROFILE_TYPES_CODE!;
export const PROFILE_TYPE_ADMINISTRATOR_CODE: string = process.env.REACT_APP_PROFILE_TYPE_ADMINISTRATOR_CODE!;
export const COLOR_PRIMARY: string = process.env.REACT_APP_COLOR_PRIMARY!;
export const COLOR_SECONDARY: string = process.env.REACT_APP_COLOR_SECONDARY!;
export const NAVBAR_HEIGHT: string = process.env.REACT_APP_NAVBAR_HEIGHT!;
export const NAVBARSUB_HEIGHT: string = process.env.REACT_APP_NAVBARSUB_HEIGHT!;
export const HEIGHT_TABLE_CONTAINER: string = process.env.REACT_APP_HEIGHT_TABLE_CONTAINER!;
export const HEIGHT_CONTAINER: string = process.env.REACT_APP_HEIGHT_CONTAINER!;
export const NAVBAR_HEIGHT_SUM: string = process.env.REACT_APP_NAVBAR_HEIGHT_SUM!;
export const CLIENT_TYPES_CODE: string = process.env.REACT_APP_CLIENT_TYPES_CODE!;
export const CLIENT_TYPE_LEGAL_CODE: string = process.env.REACT_APP_CLIENT_TYPE_LEGAL_CODE!;
export const CLIENT_TYPE_LEGAL_VALUE: string = process.env.REACT_APP_CLIENT_TYPE_LEGAL_VALUE!;
export const CLIENT_TYPE_NATURAL_CODE: string = process.env.REACT_APP_CLIENT_TYPE_NATURAL_CODE!;
export const CLIENT_TYPE_NATURAL_VALUE: string = process.env.REACT_APP_CLIENT_TYPE_NATURAL_VALUE!;
export const CATALOG_AMPERAGE_CODE: string = process.env.REACT_APP_CATALOG_AMPERAGE_CODE!;
export const CATALOG_POLO_CODE: string = process.env.REACT_APP_CATALOG_POLO_CODE!;
export const PANEL_BUILT_IN_TYPES_CODE: string = process.env.REACT_APP_PANEL_BUILT_IN_TYPES_CODE!;
export const TYPES_ELECTRICAL_PANEL_BUILT_IN_DESCRIPTION: string = process.env.REACT_APP_TYPES_ELECTRICAL_PANEL_BUILT_IN_DESCRIPTION!;
export const AC_CAPABILITIES_CODE: string = process.env.REACT_APP_AC_CAPABILITIES_CODE!;
export const TYPES_ELECTRICAL_PANEL_CODE: string = process.env.REACT_APP_TYPES_ELECTRICAL_PANEL_CODE!;
export const EQUIPMENT_AC_CODE: string = process.env.REACT_APP_EQUIPMENT_AC_CODE!;
export const EQUIPMENT_BOARD_ELECTRICAL_CODE: string = process.env.REACT_APP_EQUIPMENT_BOARD_ELECTRICAL_CODE!;
export const EQUIPMENT_CAMS_CODE: string = process.env.REACT_APP_EQUIPMENT_CAMS_CODE!;
export const EQUIPMENT_SECURITY_CODE: string = process.env.REACT_APP_EQUIPMENT_SECURITY_CODE!;
export const EQUIPMENT_BOMBA_CODE: string = process.env.REACT_APP_EQUIPMENT_BOMBA_CODE!;
export const EQUIPMENT_MULTIMEDIA_CODE: string = process.env.REACT_APP_EQUIPMENT_MULTIMEDIA_CODE!;
export const EQUIPMENT_UPS_CODE: string = process.env.REACT_APP_EQUIPMENT_UPS_CODE!;
export const EQUIPMENT_COMPUTER_CODE: string = process.env.REACT_APP_EQUIPMENT_COMPUTER_CODE!;
export const EQUIPMENT_DOMOTICA_CODE: string = process.env.REACT_APP_EQUIPMENT_DOMOTICA_CODE!;
export const EQUIPMENT_DOMOTICA_ALONE_CODE: string = process.env.REACT_APP_EQUIPMENT_DOMOTICA_ALONE_CODE!;
export const EQUIPMENT_GENERIC_CODE: string = process.env.REACT_APP_EQUIPMENT_GENERIC_CODE!;
export const EQUIPMENT_KITCHEN_CODE: string = process.env.REACT_APP_EQUIPMENT_KITCHEN_CODE!;
export const EQUIPMENT_VEHICLE_CODE: string = process.env.REACT_APP_EQUIPMENT_VEHICLE_CODE!;
export const EQUIPMENT_SOLAR_CODE: string = process.env.REACT_APP_EQUIPMENT_SOLAR_CODE!;
export const EQUIPMENT_GENERATOR_CODE: string = process.env.REACT_APP_EQUIPMENT_GENERATOR_CODE!;
export const CATALOG_EQUIPMENTS_CODE: string = process.env.REACT_APP_CATALOG_EQUIPMENTS_CODE!;
export const CATALOGUE_AREA_USER_CODE: string = process.env.REACT_APP_CATALOGUE_AREA_USER_CODE!;
export const CATALOGUE_TYPE_USER_CODE: string = process.env.REACT_APP_CATALOGUE_TYPE_USER_CODE!;
export const CLIENTS_POSITIONS_CODE: string = process.env.REACT_APP_CLIENTS_POSITIONS_CODE!;
export const CLIENTS_AREAS_CODE: string = process.env.REACT_APP_CLIENTS_AREAS_CODE!;
export const LINK_QR: string = process.env.REACT_APP_LINK_QR!;
export const STRING_KEY: string = process.env.REACT_APP_STRING_KEY!;
export const URL_BACKEND: string = process.env.REACT_APP_URL_BACKEND!;
export const TASK_ACTIVOS_ASIGNAR_CODE: string = process.env.REACT_APP_TASK_ACTIVOS_ASIGNAR_CODE!;
export const TASK_ACTIVOS_FILTRAR_CLIENT_CODE: string = process.env.REACT_APP_TASK_ACTIVOS_FILTRAR_CLIENT_CODE!;
export const CATALOGUE_TYPES_AC_CODE: string = process.env.REACT_APP_CATALOGUE_TYPES_AC_CODE!;
export const MAIL_HOST_CODE: string = process.env.REACT_APP_MAIL_HOST_CODE!;
export const MAIL_PORT_CODE: string = process.env.REACT_APP_MAIL_PORT_CODE!;
export const MAIL_NOTIFICATION_USER_CODE: string = process.env.REACT_APP_MAIL_NOTIFICATION_USER_CODE!;
export const MAIL_NOTIFICATION_PASS_CODE: string = process.env.REACT_APP_MAIL_NOTIFICATION_PASS_CODE!;
export const MAIL_RESET_PASSWORD_USER_CODE: string = process.env.REACT_APP_MAIL_RESET_PASSWORD_USER_CODE!;
export const MAIL_RESET_PASSWORD_PASS_CODE: string = process.env.REACT_APP_MAIL_RESET_PASSWORD_PASS_CODE!;
export const COMPANY_CODE: string = process.env.REACT_APP_COMPANY_CODE!;
export const COMPANY_RUC_CODE: string = process.env.REACT_APP_COMPANY_RUC_CODE!;
export const COMPANY_ADDRESS_CODE: string = process.env.REACT_APP_COMPANY_ADDRESS_CODE!;
export const COMPANY_PHONE_CODE: string = process.env.REACT_APP_COMPANY_PHONE_CODE!;
export const COMPANY_WEB_PAGE_CODE: string = process.env.REACT_APP_COMPANY_WEB_PAGE_CODE!;
export const CATALOGUE_TYPES_AC_SPLIT_VALUE: string = process.env.REACT_APP_CATALOGUE_TYPES_AC_SPLIT_VALUE!;
export const CATALOGUE_TYPES_AC_SPLITDUCTO_VALUE: string = process.env.REACT_APP_CATALOGUE_TYPES_AC_SPLITDUCTO_VALUE!;
export const IDENTIFICATION_TYPE_CEDULA_CODE: string = process.env.REACT_APP_IDENTIFICATION_TYPE_CEDULA_CODE!;
export const CATALOGUE_TYPE_WORK_OT_CODE: string = process.env.REACT_APP_CATALOGUE_TYPE_WORK_OT_CODE!;
export const EQUIPMENT_STATUSES_CODE: string = process.env.REACT_APP_EQUIPMENT_STATUSES_CODE!;
export const ASSIGN_OT_TO_CLIENT_CODE: string = process.env.REACT_APP_ASSIGN_OT_TO_CLIENT_CODE!;
export const ASSIGN_USER_TO_CLIENT_CODE: string = process.env.REACT_APP_ASSIGN_USER_TO_CLIENT_CODE!;
export const TASK_FILTER_BY_CLIENT_INTO_USERS_CODE: string = process.env.REACT_APP_TASK_FILTER_BY_CLIENT_INTO_USERS_CODE!;
export const WHATSAPP_PARAMETERS_CODE: string = process.env.REACT_APP_WHATSAPP_PARAMETERS_CODE!;
export const CATALOGUE_VOLTAGE_CODE: string = process.env.REACT_APP_CATALOGUE_VOLTAGE_CODE!;