import React, { Fragment, useEffect, useState } from "react";
import { getAllParameterTypeClient, getAllParameterTypeIdentification, supplierPost } from "../../../apis";
import { Button, Chip, Grid, InputAdornment, MenuItem, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import * as Yup from 'yup';
import { ParameterI } from "../../../interfaces";
import { CLIENT_TYPE_LEGAL_CODE, CLIENT_TYPE_LEGAL_VALUE, CLIENT_TYPE_NATURAL_CODE, CLIENT_TYPE_NATURAL_VALUE } from "../../../constants";
import { StringHelper } from "../../../helpers";
import {
    Person as PersonIcon
} from "@mui/icons-material";

interface NewSupplierPropsI {
    refetch: () => void;
    onClose: () => void;
    clientId?:number|null
}

export const NewSupplier = ({
    refetch,
    onClose,
    clientId,
}: NewSupplierPropsI) => {
    const stringHelper = new StringHelper();
    const [typesIdentification, setTypesIdentification] = useState<ParameterI[]>([]);
    const [typesClient, setTypesClient] = useState<ParameterI[]>([]);
    const [isProcessing, setProcessing] = useState(false);

    useEffect(() => {
        handleGetData();
    }, []);

    const formik = useFormik({
        initialValues: {
            docType: "",
            docTypeId: null,
            docNumber: "",
            firstName: "",
            lastName: "",
            name: "",
            businessName: "",
            mail: "",
            phone: "",
            typeSupplierId: null,
            typeSupplier: "",
        },
        validationSchema: Yup.object({
            docNumber: Yup.string()
                .trim()
                .min(2, 'Too Short!')
                .max(16, 'Too Long!')
                .required('Required'),
            docType: Yup.string()
                .trim()
                .uppercase()
                .min(2, 'Too Short!')
                .max(11, 'Too Long!')
                .required('Required'),
            lastName: Yup.string()
                .when('typeSupplier', {
                    is: CLIENT_TYPE_NATURAL_VALUE,
                    then: (schema) => (
                        schema.min(2, 'Too Short!'),
                        schema.max(150, 'Too Long!'),
                        schema.required()
                    ),
                    otherwise: (schema) => schema.notRequired()
                }),
            firstName: Yup.string()
                .when('typeSupplier', {
                    is: CLIENT_TYPE_NATURAL_VALUE,
                    then: (schema) => (
                        schema.min(2, 'Too Short!'),
                        schema.max(150, 'Too Long!'),
                        schema.required()
                    ),
                    otherwise: (schema) => schema.notRequired()
                }),
            name: Yup.string()
                .when('typeSupplier', {
                    is: CLIENT_TYPE_LEGAL_VALUE,
                    then: (schema) => (
                        schema.min(2, 'Too Short!'),
                        schema.max(200, 'Too Long!'),
                        schema.required()
                    ),
                    otherwise: (schema) => schema.notRequired()
                }),
        }),
        onSubmit: async (values, { validateForm, submitForm }) => {
            setProcessing(true);
            try {
                const {
                    docTypeId,
                    docNumber,
                    firstName,
                    lastName,
                    name,
                    businessName,
                    mail,
                    phone,
                    typeSupplierId,
                } = values;
                const obj = {
                    docTypeId,
                    docNumber,
                    firstName,
                    lastName,
                    name,
                    businessName,
                    mail,
                    phone,
                    typeSupplierId,
                    clientId
                }
                const { data: dataSupplier } = await supplierPost(obj);
                enqueueSnackbar(dataSupplier?.alert, { variant: "success" });
                setProcessing(false);
                refetch();
                formik.resetForm();
                onClose();
            } catch (err: any) {
                enqueueSnackbar(err.toString(), { variant: "error" });
                setProcessing(false);
            }
        }
    });

    const handleGetData = async () => {
        try {

            const { data: dataTypesIdentification } = await getAllParameterTypeIdentification();
            if (!dataTypesIdentification) return;
            setTypesIdentification(dataTypesIdentification);

            const { data: dataTypeClient } = await getAllParameterTypeClient();
            if (!dataTypeClient) return;
            setTypesClient(dataTypeClient);

        } catch (err: any) {
            enqueueSnackbar(err.toString(), { variant: "error" })
        }
    }

    const handleLegal = (legal: boolean) => {

        if (legal) {
            const fill = typesClient.find(el => el.code === CLIENT_TYPE_LEGAL_CODE)
            formik.setFieldValue("typeSupplierId", fill!.id);
            formik.setFieldValue("typeSupplier", fill!.value);
        } else {
            const fill = typesClient.find(el => el.code === CLIENT_TYPE_NATURAL_CODE)
            formik.setFieldValue("typeSupplierId", fill!.id);
            formik.setFieldValue("typeSupplier", fill!.value);
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Chip label="Nuevo Proveedor" color="info" />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Stack direction="row" gap={1}>
                        <TextField
                            label="Tipo"
                            variant="outlined"
                            size="small"
                            name="docType"
                            onChange={(e) => {
                                formik.setFieldValue("docType", e.target.value);
                                const fill = typesIdentification.find(el => el.value === e.target.value);

                                if (!fill) return;

                                formik.setFieldValue("docTypeId", fill.id);
                            }}
                            select
                            sx={{ width: "35%" }}
                            error={!!formik.errors.docType ?? false}
                            helperText={formik.errors.docType ?? ""}
                        >
                            {
                                typesIdentification.map(el => (
                                    <MenuItem key={el.value} value={el.value}>
                                        {el.value}
                                    </MenuItem>
                                ))
                            }
                        </TextField>

                        <TextField
                            label="Identificación"
                            variant="outlined"
                            size="small"
                            sx={{ width: "65%" }}
                            name="docNumber"
                            value={formik.values.docNumber || ""}
                            onChange={(e) => {
                                formik.setFieldValue("docNumber", e.target.value);
                            }}
                            onBlur={(e) => {

                                const sliceVal = e.target.value.charAt(2);
                                if (sliceVal == "9") {
                                    handleLegal(true)
                                } else {
                                    handleLegal(false)
                                }
                            }}
                            error={!!formik.errors.docNumber ?? false}
                            helperText={formik.errors.docNumber ?? ""}
                        />
                    </Stack>
                </Grid>
                {formik.values.typeSupplier === CLIENT_TYPE_NATURAL_VALUE ?
                    <Fragment>
                        <Grid item xs={12}>
                            <TextField
                                label="Apellidos"
                                variant="outlined"
                                name="lastName"
                                size="small"
                                fullWidth
                                value={formik.values.lastName || ""}
                                onChange={(e) => {
                                    formik.setFieldValue("lastName", e.target.value);
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        formik.setFieldValue("lastName", e.target.value);
                                        return;
                                    }

                                    const value_ = e.target.value
                                        .trim()
                                        .toLowerCase()
                                        .split(" ")
                                        .map(el => stringHelper.capitalizeFirstLetter(el))
                                        .join(" ");

                                    formik.setFieldValue("lastName", value_);
                                }}
                                error={!!formik.errors.lastName ?? false}
                                helperText={formik.errors.lastName ?? ""}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Nombres"
                                variant="outlined"
                                name="firstName"
                                size="small"
                                fullWidth
                                value={formik.values.firstName || ""}
                                onChange={(e) => {
                                    formik.setFieldValue("firstName", e.target.value);
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        formik.setFieldValue("firstName", e.target.value);
                                        return;
                                    }

                                    const value_ = e.target.value
                                        .trim()
                                        .toLowerCase()
                                        .split(" ")
                                        .map(el => stringHelper.capitalizeFirstLetter(el))
                                        .join(" ");

                                    formik.setFieldValue("firstName", value_);

                                }}
                                error={!!formik.errors.firstName ?? false}
                                helperText={formik.errors.firstName ?? ""}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Fragment> :
                    <Grid item xs={12}>
                        <TextField
                            label="Razón Social"
                            variant="outlined"
                            name="name"
                            size="small"
                            fullWidth
                            value={formik.values.name || ""}
                            onChange={(e) => {
                                formik.setFieldValue("name", e.target.value);
                            }}
                            onBlur={(e) => {
                                formik.setFieldValue("name", e.target.value.trim());
                                formik.setFieldValue("businessName", e.target.value.trim());
                            }}
                            error={!!formik.errors.name ?? false}
                            helperText={formik.errors.name ?? ""}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>,
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        label="Nombre Comercial"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="businessName"
                        value={formik.values.businessName || ""}
                        onChange={(e) => {
                            formik.setFieldValue("businessName", e.target.value.toUpperCase());
                        }}
                        onBlur={(e) => {
                            formik.setFieldValue("businessName", e.target.value.trim());
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Correo"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="mail"
                        value={formik.values.mail || ""}
                        onChange={(e) => {
                            formik.setFieldValue("mail", e.target.value);
                        }}
                        onBlur={(e) => {
                            formik.setFieldValue("mail", e.target.value.trim());
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Celular"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="phone"
                        value={formik.values.phone || ""}
                        onChange={(e) => {
                            formik.setFieldValue("phone", e.target.value);
                        }}
                        onBlur={(e) => {
                            formik.setFieldValue("phone", e.target.value.trim());
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+593</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Stack direction="row" gap={1} justifyContent="flex-end">
                        <Button variant="outlined" color="error" size="small" onClick={onClose} disabled={isProcessing}>
                            Cancelar
                        </Button>
                        <Button variant="contained" color="success" type='submit' size="small" disabled={isProcessing}>
                            Crear
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    )
}