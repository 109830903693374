import React from "react";
import {
    Navigate,
} from "react-router-dom";


const ProfileListComponent = React.lazy(() => import("../../../../../components/profiles/components/list/page"));
const ProfileAddComponent = React.lazy(() => import("../../../../../components/profiles/components/add/page"));

export const profilesRoute = [
    {
        path: 'list',
        element: <ProfileListComponent />
    },
    {
        path: 'add',
        element: <ProfileAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/options/profiles/add" />
    },
    {
        path: 'edit/:id',
        element: <ProfileAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/options/profiles/list" />
    },
];