import { useQuery } from "@tanstack/react-query";
import { poloApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsPoloI,
    PoloI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getPolos = async ({
    pagination, 
    sorting, 
    globalFilter,
}:PropsPoloI):Promise<ServiceResponseI<DataI<PoloI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));

        //headers: { 'Authorization': validateToken
        const { data } = await poloApi.get<ServiceResponseI<DataI<PoloI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            
            LogoutSystem();
        }

        throw new Error((err as Error).message)
    }

    
}

export const getAllPolos = async ():Promise<ServiceResponseI<PoloI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await poloApi.get<ServiceResponseI<PoloI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const poloPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await poloApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const poloPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await poloApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const poloById = async (id: number): Promise<ServiceResponseI<PoloI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await poloApi.get<ServiceResponseI<PoloI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const poloDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await poloApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const usePolos = ({
    sorting,
    pagination,
    globalFilter,
}:PropsPoloI)=>{

    
    const polosQuery = useQuery(
        ["polos", { pagination, sorting, globalFilter }],
        ()=>getPolos({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        polosQuery,
    };
}