import React from "react";
import "./description.css";
import { useWidth } from "../../../../hooks";

export interface CustomDescriptionItem {
  key: string;
  label: string;
  content: React.ReactNode;
  order?: number;
  width?: string;
  fontSize?: string;
  fullWidth?: boolean;
  noShow?: boolean; // Nueva propiedad para manejar la visibilidad
}

interface CustomDescriptionsProps {
  items: CustomDescriptionItem[];
  columns?: number;
}

export const CustomDescriptions: React.FC<CustomDescriptionsProps> = ({
  items,
  columns = 2,
}) => {
    const width = useWidth();
    const visibleItems = items.filter(item => !item.noShow);
  return (
    <div className="custom-descriptions">
      {visibleItems.map((item, index) => (
        <div
          key={index}
          className="custom-item"
          style={{
            order: item.order || 0,
            width: width === "xs" ? `calc(100% - 16px)`:
            
            item.fullWidth
              ? `calc(100% - 16px)`
              : `calc(${100 / columns}% - 16px)`,
            fontSize: item.fontSize || "16px",
          }}
        >
          <span className="custom-item-label">{item.label} </span>
          <span className="custom-item-content">{item.content}</span>
        </div>
      ))}
    </div>
  );
};
