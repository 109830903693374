import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  ReactElement,
  Ref,
  useMemo,
  memo,
} from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  Button as ButtonAntd,
  ConfigProvider,
  Input,
  Popconfirm,
  Space,
} from "antd";
import {
  clientById,
  equipmentAcById,
  equipmentSendReportById,
} from "../../apis/";
import {
  CloseOutlined,
  DeleteFilled,
  MailFilled,
  PhoneOutlined,
  PlusCircleOutlined,
  SendOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { enqueueSnackbar } from "notistack";
import { COLOR_PRIMARY } from "../../constants";
import { v4 as uuidV4 } from "uuid";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SendReportPropsI {
  equipment: number;
  open: boolean;
  handleClose: () => void;
}

interface ValuesI {
  uuid: string;
  value: string;
}

export const SendReport = ({
  open,
  equipment,
  handleClose,
}: SendReportPropsI) => {
  const [receivers, setReceivers] = useState<ValuesI[]>([]);
  const [receiversNum, setReceiversNum] = useState<ValuesI[]>([]);
  const [isSending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = async () => {
    try {
      const { data: dataEquipment } = await equipmentAcById(equipment);
      if (!dataEquipment) return;

      const { data: dataClients } = await clientById(dataEquipment.clientId!);
      if (!dataClients) return;

      const arrMails = [];
      const arrPhones = [];
      if (dataClients.mail) {
        arrMails.push(dataClients.mail);
      }

      if (dataClients.phone && dataClients.phone.length >= 9) {
        arrPhones.push(dataClients.phone);
      }

      for (const x of dataClients.clientInvoice) {
        for (const y of x.mails) {
          if (y.value) {
            arrMails.push(y.value);
          }
        }
        for (const y of x.phones) {
          if (y.value && y.value.length >= 9) {
            arrPhones.push(`0${y.value}`);
          }
        }
      }
      setReceiversNum(arrPhones.map((el) => ({ uuid: uuidV4(), value: el })));
      setReceivers(arrMails.map((el) => ({ uuid: uuidV4(), value: el })));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleSendReport = async () => {
    try {
      setSending(true);

      const obj = {
        mails:receivers
          .filter(el=> el.value)
          .filter(el=> el.value.length > 9)
          .map(el=>el.value),
        phones:receiversNum
        .filter(el=> el.value)
        .filter(el=> el.value.length > 9)
        .map(el=>el.value),
        equipment,
      };

      const { data: dataSend } = await equipmentSendReportById(obj);
      enqueueSnackbar(dataSend, { variant: "success" });
      setSending(false);
    } catch (err: any) {
      setSending(false);
      enqueueSnackbar(err.toString(), { variant: "error" });
    }
  };

  const handleAddPhone = useCallback(() => {
    const obj = {
      uuid: uuidV4(),
      value: "",
    };
    receiversNum.push(obj);
    setReceiversNum([...receiversNum]);
  },[receiversNum]);

  const handleUpdatePhone = useCallback((uuid: string, value: string) => {
    setReceiversNum((rec) =>
      rec.map((el) => {
        if (el.uuid === uuid) return { ...el, value };
        return el;
      })
    );
  },[receiversNum]);

  const handleDeletePhone = useCallback((uuid: string) => {
    setReceiversNum((rec) => rec.filter((el) => el.uuid !== uuid));
  },[receiversNum]);

  const handleAddMail = useCallback(() => {
    const obj = {
      uuid: uuidV4(),
      value: "",
    };
    receivers.push(obj);
    setReceivers([...receivers]);
  },[receivers]);

  const handleUpdateMail = useCallback((uuid: string, value: string) => {
    setReceivers((rec) =>
      rec.map((el) => {
        if (el.uuid === uuid) return { ...el, value };
        return el;
      })
    );
  },[receivers]);

  const handleDeleteMail = useCallback((uuid: string) => {
    setReceivers((rec) => rec.filter((el) => el.uuid !== uuid));
  },[receivers]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-send-report"
    >
      <DialogTitle
        sx={{
          bgcolor: COLOR_PRIMARY,
          color: "white",
          position: "relative",
          fontWeight: "bolder",
        }}
      >
        {"Envío de Reporte de Activo"}
        <ButtonAntd
          type="primary"
          danger
          style={{ position: "absolute", right: 5, top: 10 }}
          onClick={handleClose}
          icon={<CloseOutlined />}
        />
      </DialogTitle>
      <DialogContent sx={{ p:0 , position:"relative"}}>
        <Grid container spacing={0} >
          <Grid item xs={6} sx={{ p: 1, bgcolor:"aliceblue", borderRadius:1}}>
            <Grid container 
              spacing={1} 
              alignContent="flex-start"
              alignItems="flex-start"
              sx={{ minHeight: 300, maxHeight: 300, height:300, overflowY:"auto"}}>
              <Grid item xs={12} sx={{position:"sticky", top:0, zIndex:600, bgcolor:"aliceblue"}}>
                <Space.Compact style={{ width: "100%" }}>
                  <ButtonAntd
                    style={{ width: "50%" }}
                    type="dashed"
                    onClick={handleAddPhone}
                    icon={<WhatsAppOutlined style={{color:"green"}}/>}
                  >
                    Celulares
                  </ButtonAntd>
                  <ButtonAntd
                    style={{ width: "50%" }}
                    onClick={handleAddPhone}
                    icon={<PlusCircleOutlined />}
                  >
                    Agregar
                  </ButtonAntd>
                </Space.Compact>
              </Grid>
              {receiversNum.map((el) => (
                <Grid item xs={12} key={el.uuid}>
                  <ItemComponent
                    onUpdate={handleUpdatePhone}
                    onDelete={handleDeletePhone}
                    placeholder="0988776655"
                    uuid={el.uuid}
                    name="Celular"
                    value={el.value}
                    maxLength={10}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ p: 1, bgcolor:"ghostwhite", borderRadius:1}}>
            <Grid container 
              spacing={1} 
              alignContent="flex-start"
              alignItems="flex-start"
              sx={{ minHeight: 300, maxHeight: 300, height:300, overflowY:"auto"}}>
              <Grid item xs={12} sx={{position:"sticky", top:0, zIndex:600, bgcolor:"ghostwhite"}}>
                <Space.Compact style={{ width: "100%" }}>
                  <ButtonAntd
                    style={{ width: "50%" }}
                    type="dashed"
                    onClick={handleAddMail}
                    icon={<MailFilled style={{color:"blue"}}/>}
                  >
                    Correos
                  </ButtonAntd>
                  <ButtonAntd
                    style={{ width: "50%" }}
                    onClick={handleAddMail}
                    icon={<PlusCircleOutlined />}
                  >
                    Agregar
                  </ButtonAntd>
                </Space.Compact>
              </Grid>
              {receivers.map((el) => (
                <Grid item xs={12} key={el.uuid}>
                  <ItemComponent
                    onUpdate={handleUpdateMail}
                    onDelete={handleDeleteMail}
                    placeholder="correo@mail.com"
                    uuid={el.uuid}
                    name="Correo"
                    value={el.value}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px dashed gainsboro" }}>
        <ButtonAntd
          type="dashed"
          danger
          onClick={handleClose}
          icon={<CloseOutlined />}
        >
          Cancelar
        </ButtonAntd>
        <ConfigProvider
          theme={{
            components: {
              Popconfirm: {
                zIndexPopup: 1301,
              },
            },
          }}
        >
          <Popconfirm
            title="Enviar Reporte"
            description="Información Correcta?"
            onConfirm={handleSendReport}
            okText="Si"
            cancelText="No"
          >
            <ButtonAntd
              type="primary"
              icon={<SendOutlined />}
              loading={isSending || loading}
              disabled={isSending || loading}
            >
              Enviar
            </ButtonAntd>
          </Popconfirm>
        </ConfigProvider>
      </DialogActions>
    </Dialog>
  );
};

type names = "Celular" | "Correo";

interface ItemProps {
  uuid: string;
  onDelete: (uuid: string) => void;
  onUpdate: (uuid: string, value: string) => void;
  name: names;
  placeholder: string;
  value: string;
  maxLength?:number;
}

const ItemComponent = memo(({
  onDelete,
  name,
  onUpdate,
  placeholder,
  uuid,
  value: elvalue,
  maxLength,
}: ItemProps) => {
  const [value, setValue] = useState(elvalue);

  return (
    <Input
      addonBefore={name}
      value={value}
      maxLength={maxLength ? maxLength : 1000}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        onUpdate(uuid, value?.trim() ?? "");
        setValue(e.target.value?.trim() ?? "");
      }}
      placeholder={placeholder}
      style={{ width: "100%" }}
      status={value.length < 10 ? "error" : ""}
      suffix={
        <ButtonAntd
          danger
          size="small"
          onClick={() => onDelete(uuid)}
          icon={<DeleteFilled />}
        />
      }
    />
  );
});
