import { Environments } from '../../enums';
import moment from 'moment';
moment.locale('es');

export class DateFormatHelper {

  getDateFormatInput(date: string): string {
    const laZona = "America/Guayaquil";
    return moment(date).format().split('T')[0];
  }

  getDateFormat(date: string, format = 'LLL'): string {
    const hours = Environments.TIMEZONE_HOURS;
    return date !== '' && date !== null && date !== undefined
      ? moment(date).subtract(hours, 'hour').format(format)
      : '';
  }

  getDateFormatWithoutSubstract(date: string, format = 'LLL'): string {
    return date !== '' && date !== null && date !== undefined
      ? moment(date).format(format)
      : '';
  }

  getCurrentDateTime() {
    const hours = Environments.TIMEZONE_HOURS_MONGO;
    const date = new Date().toISOString();
    return moment(date).subtract(hours, 'hour').format();
  }

  getDateTime() {
    const hours = Environments.TIMEZONE_HOURS;
    const date = new Date().toISOString();
    return moment(date).subtract(hours, 'hour').format('YYYMMDDHHmmss');
  }

  getTime(){
    const date = new Date().toISOString();
    return moment(date).format("HH:mm:ss");
  }

  addDateTime(dateS: string, add: number) {
    const date = new Date(dateS).toISOString();
    return moment(date).add(add, 'days').format();
  }

  getElapsedDays(creationDate: string, validateDate: string) {
    const dateStart = moment(creationDate);
    const dateEnd = moment(validateDate);
    return dateEnd.diff(dateStart, 'days');
  }

  getDiffString(creationDate: string){
    const dateStart = moment(creationDate);
    const dateEnd = moment(this.getCurrentDateTime());
    const diff_ = dateEnd.diff(dateStart, 'days');
    return `${diff_} ${diff_ > 1 ? "Días" : "Día"} de Diferencia`
  }
}
