import React from "react";
import {
  Navigate,
} from "react-router-dom";


const UserEditComponent = React.lazy(() => import("../../../components/user/components/information/page"));


export const userRoute = [

  {
    path: 'information',
    element: <UserEditComponent />,
  },
  {
    path: '',
    element: <Navigate replace to="/user/information" />
  },
]