import React, { useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import {
    Image,
    Segmented,
} from 'antd';
import {
    EquipmentBoardElectricalI,
    EquipmentItemI,
} from '../../../../libs/interfaces';
import { MRT_ColumnDef, MRT_DensityState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useWidth } from '../../../../libs/hooks';
import noImage from "../../../../images/no-image.png";
import { AppstoreOutlined, BarsOutlined, ScheduleOutlined } from '@ant-design/icons';
import { EquipmentTableConsumables } from './consumables';
import { EquipmentTableOts } from './ots';

export const ModalBodyBoardElectrical = ({
    data
}: { data: EquipmentBoardElectricalI }) => {
    const width = useWidth();
    const [density, setDensity] = useState<MRT_DensityState>("compact");
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [sorting, setSorting] = useState([
        { id: "order", desc: false },
    ]);
    const [view, setView] = useState("Elementos");

    const columns = useMemo<MRT_ColumnDef<EquipmentItemI>[]>(
        () => [
            {
                accessorKey: 'order',
                header: 'ID',
                enableEditing: false,
                minSize: 5,
                maxSize: 5,
                size: 5,
                grow: false,
            },
            {
                accessorKey: 'group',
                header: 'Grupo',
                enableEditing: false,
                minSize: 80,
                size: 80,
                grow: false
            },
            {
                accessorKey: 'observation',
                header: 'Observación',
                enableEditing: false,
                minSize: 200,
            },
            {
                accessorKey: 'brand',
                header: 'Marca',
                enableEditing: false,
                minSize: 90,
                maxSize: 100,
                muiTableBodyCellProps: {
                    align: 'center',
                  },
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
            },
            {
                accessorKey: 'amperage',
                header: 'Amperaje',
                enableEditing: false,
                minSize: 50,
                maxSize: 50,
                muiTableBodyCellProps: {
                    align: 'center',
                  },
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
            },
            {
                accessorKey: 'polo',
                header: 'Polo',
                enableEditing: false,
                minSize: 50,
                maxSize: 50,
                muiTableBodyCellProps: {
                    align: 'center',
                  },
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
            },

        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: data.items,
        createDisplayMode: 'row', // ('modal', and 'custom' are also available)
        editDisplayMode: 'cell', // ('modal', 'row', 'table', and 'custom' are also available)
        enableCellActions: true,
        enableClickToCopy: 'context-menu',
        enableColumnPinning: true,
        enableEditing: false,
        enableRowActions: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableDensityToggle: true,
        onDensityChange: setDensity,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getRowId: (row) => row.uuid,
        muiTableContainerProps: ({ table }) => {
            return {
                sx: {
                    minHeight: table.options.state.isFullScreen ? `calc(100vh - 100px)` : `calc(100vh - 253px)`,
                    maxHeight: table.options.state.isFullScreen ? `calc(100vh - 100px)` : `calc(100vh - 253px)`,
                    height: table.options.state.isFullScreen ? `calc(100vh - 100px)` : `calc(100vh - 253px)`,
                }
            }
        },

        initialState: {
            density,
            pagination,
            sorting,
            columnPinning: {
                left: ['mrt-row-actions'],
            },
        },
        state: {
            density,
            pagination,
            sorting,
        },
    });

    return (
        <Grid
            container
            spacing={0}
        // sx={{background: 'linear-gradient(90deg, #032d81,#8b9de5,#ffffff)'}}
        >
            <Grid item xs={12}>
                <Segmented
                    value={view || "Elementos"}
                    onChange={setView}
                    options={[
                        { label: 'Elementos', value: 'Elementos', icon: <BarsOutlined /> },
                        { label: 'Ots', value: 'Ots', icon: <ScheduleOutlined /> },
                        { label: 'Consumibles', value: 'Consumibles', icon: <AppstoreOutlined /> },
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={8.5}>
                {
                    view === "Elementos"
                        ? <MaterialReactTable table={table} />
                        : view === "Ots"
                            ? <EquipmentTableOts data={data.ots} height='253px' />
                            : <EquipmentTableConsumables data={data.consumables} height='253px' />
                }

            </Grid>
            <Grid item xs={12} md={3.5} sx={{ display: 'grid', placeContent: "center", border: "1px dashed gainsboro" }}>
                <Image
                    width="100%"
                    src={data.photoPanel ?? noImage}
                    fallback={noImage}
                />
            </Grid>

        </Grid>
    )
}