import { useQuery } from "@tanstack/react-query";
import { equipmentApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsEquipmentI,
    EquipmentI,
    EquipmentSimpleI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getEquipments = async ({
    pagination, 
    sorting, 
    globalFilter,
    typeEquipment,
    brand,
    client,
}:PropsEquipmentI):Promise<ServiceResponseI<DataI<EquipmentI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        typeEquipment && params.append('typeEquipment', typeEquipment.toString());
        brand && params.append('brand', brand.toString());
        client && params.append('client', client.toString());

        //headers: { 'Authorization': validateToken
        const { data } = await equipmentApi.get<ServiceResponseI<DataI<EquipmentI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            
            LogoutSystem();
        }

        throw new Error((err as Error).message)
    }

    
}

export const getAllEquipments = async ():Promise<ServiceResponseI<EquipmentI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await equipmentApi.get<ServiceResponseI<EquipmentI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const getAllEquipmentsByClientId = async (id:number):Promise<ServiceResponseI<EquipmentSimpleI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await equipmentApi.get<ServiceResponseI<EquipmentSimpleI[]>>(`/all-equipments-by-client-id/${id}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const getAllNoPaginationEquipmentsByClientIdByTypeEquipmentId = async (client:number, typeEquipment?:number|null):Promise<ServiceResponseI<EquipmentSimpleI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();
        client && params.append('client', client.toString());
        typeEquipment && params.append('typeEquipment', typeEquipment.toString());


        const { data } = await equipmentApi.get<ServiceResponseI<EquipmentSimpleI[]>>(`/all-no-pagination-client-id-type-id`,{ params, headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const getAllEquipmentsParents = async ()=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await equipmentApi.get<ServiceResponseI<EquipmentI[]>>("/all-equipments-parents",{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const equipmentPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentById = async (id: number): Promise<ServiceResponseI<EquipmentI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentApi.get<ServiceResponseI<EquipmentI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentPdfById = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentApi.get<ServiceResponseI<string>>(`/generate-pdf-by-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentSendReportById = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentApi.post<ServiceResponseI<string>>(`/send-report-by-equipment-id`, getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const useEquipments = ({
    sorting,
    pagination,
    globalFilter,
    typeEquipment,
    brand,
    client,
}:PropsEquipmentI)=>{

    
    const equipmentsQuery = useQuery(
        ["equipments", { pagination, sorting, globalFilter, typeEquipment, brand, client }],
        ()=>getEquipments({ pagination, sorting, globalFilter, typeEquipment, brand, client }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        equipmentsQuery,
    };
}