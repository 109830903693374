import { lazy } from "react";
import {
    Navigate,
} from "react-router-dom";

const ModuleListComponent = lazy(() => import("../../../../../components/module/components/list/page"));
const ModuleAddComponent = lazy(() => import("../../../../../components/module/components/add/page"));

export const moduleRoute = [
    {
        path: 'list',
        element: <ModuleListComponent />
    },
    {
        path: 'add',
        element: <ModuleAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/modules/add" />
    },
    {
        path: 'edit/:id',
        element: <ModuleAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/modules/list" />
    },
];