import { useQuery } from "@tanstack/react-query";
import { supplierApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsSupplierI,
    SupplierI,
    ObjPostI,
    OneSupplierI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getSuppliers = async ({
    pagination, 
    sorting, 
    globalFilter,
}:PropsSupplierI):Promise<ServiceResponseI<DataI<SupplierI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));

        //headers: { 'Authorization': validateToken
        const { data } = await supplierApi.get<ServiceResponseI<DataI<SupplierI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            
            LogoutSystem();
        }

        throw new Error((err as Error).message)
    }

    
}

export const getAllSuppliers = async ():Promise<ServiceResponseI<SupplierI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await supplierApi.get<ServiceResponseI<SupplierI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const getAllSuppliersbyClientId = async (client:number):Promise<ServiceResponseI<OneSupplierI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await supplierApi.get<ServiceResponseI<OneSupplierI[]>>(`/all-by-client-id/${client}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const supplierPost = async<T>(getForms: T): Promise<ServiceResponseI<ObjPostI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await supplierApi.post<ServiceResponseI<ObjPostI>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const supplierPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await supplierApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const supplierById = async (id: number): Promise<ServiceResponseI<SupplierI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await supplierApi.get<ServiceResponseI<SupplierI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const supplierDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await supplierApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useSuppliers = ({
    sorting,
    pagination,
    globalFilter,
}:PropsSupplierI)=>{

    
    const suppliersQuery = useQuery(
        ["suppliers", { pagination, sorting, globalFilter }],
        ()=>getSuppliers({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        suppliersQuery,
    };
}