import { IV_CRYPTO, KEY_CRYPTO } from '../../constants';
import crypto from 'crypto-js';
import forge from 'node-forge';

/**
 * Función para encriptar texto.
 * @param text Texto a encriptar
 * @returns texto encriptado
 */
export const encryptHelper = (text: string) => {
  const key = crypto.enc.Utf8.parse(KEY_CRYPTO);
  const iv = crypto.enc.Utf8.parse(IV_CRYPTO);
  const encryptedCP = crypto.AES.encrypt(text, key, { iv: iv });
  return encryptedCP.toString();
};
/**
 * Función para desencriptar texto.
 * @param cryptText texto encriptado
 * @returns texto desencriptado
 */
export const decryptHelper = (cryptText: string) => {
  const key = crypto.enc.Utf8.parse(KEY_CRYPTO);
  const iv = crypto.enc.Utf8.parse(IV_CRYPTO);
  const cipherParams = crypto.lib.CipherParams.create({
    ciphertext: crypto.enc.Base64.parse(cryptText),
  });
  const decryptedFromText = crypto.AES.decrypt(cipherParams, key, { iv: iv });
  return decryptedFromText.toString(crypto.enc.Utf8);
};

export const encriptarPassword = (
  usuario: string,
  contrasenia: string
): string => {
  let md = forge.md.sha256.create();
  md.update(contrasenia);
  let hashContrasenia: string = md.digest().toHex();
  hashContrasenia =
    hashContrasenia.substring(2, 39) + hashContrasenia.substring(5, 48);

  md = forge.md.sha256.create();
  md.update(usuario);
  const hashUsuario: string = md.digest().toHex();

  const clave = hashContrasenia + hashUsuario;

  md = forge.md.sha256.create();
  md.update(clave);

  const hashClave: string = md.digest().toHex();
  return hashClave;
};
