import { ModuleI, OptionI } from "../../interfaces";
import { getAllModulesByProfileId } from "../../apis";
import { create,  } from "zustand";
import { persist, createJSONStorage,  } from 'zustand/middleware'

type State = {
    options: OptionI[];
    modules: ModuleI[];
    profileId:string;
    avatar:string;
    selectClient:number|null;
    cloneCatalogue:number|null;
    cloneOption:number|null;
    cloneTask:number|null;
}

type Actions = {
    setProfileId: (profileId: string) => void;
    setOptions: (options: OptionI[]) => void;
    setOptionsFirst: (options: OptionI[]) => void;
    setModules: (modules: ModuleI[]) => void;
    setAvatar:(avatar:string)=>void;
    reset: () => void;
    resetOptions: () => void;
    resetModules: () => void;
    getModulesFromApi:()=>void;
    setSelectClient:(client:number|null)=>void;
    setCloneCatalogue:(clone:number|null)=>void;
    setCloneOption:(clone:number|null)=>void;
    setCloneTask:(task:State['cloneTask'])=>void;

}

const initialState: State = {
    options: [],
    modules: [],
    profileId:"",
    avatar:"",
    selectClient:null,
    cloneCatalogue:null,
    cloneOption:null,
    cloneTask:null,

}


export const useRouterStore = create<State & Actions>()(
    persist(
        (set, get) => ({
            options: [],
            modules: [], 
            profileId:"",
            avatar:"",
            selectClient:null,
            cloneCatalogue:null,
            cloneOption:null,
            cloneTask:null,
            reset: () => set(initialState),
            resetOptions: () => set((state)=>({options:[]})),
            resetModules: () => set((state)=>({modules:[]})),
            getModulesFromApi:async()=>{
                const itRouter = localStorage.getItem(process.env.REACT_APP_TOKEN!);
                if(!itRouter){
                    localStorage.removeItem(process.env.REACT_APP_TOKEN_ROUTER!);
                    get().reset();
                    return;
                }
                
                if(!get().profileId) return;

                const { data: dataModules } = await getAllModulesByProfileId(get().profileId!);
                get().setModules(dataModules!);
            },
            setOptions: (options) => set((state) => {
                get().resetOptions();
                return { options }
            }),
            setOptionsFirst: (options) => set((state) => ({options})),
            setModules: (modules) => set((state) => ({modules})),
            setProfileId: (profileId) => set((state) => ({ profileId })),
            setAvatar: (avatar) => set((state) => ({ avatar })),
            setSelectClient: (selectClient) => set((state) => ({ selectClient })),
            setCloneCatalogue: (cloneCatalogue) => set((state) => ({ cloneCatalogue })),
            setCloneOption: (cloneOption) => set((state) => ({ cloneOption })),
            setCloneTask:(cloneTask)=>set((state)=>({ cloneTask })),
        }),{name:process.env.REACT_APP_TOKEN_ROUTER!}
    )
);