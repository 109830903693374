import React from "react";
import {
    Navigate,
} from "react-router-dom";


const MigrationListComponent = React.lazy(() => import("../../../../../components/migrations/components/list/page"));

export const migrationsRoute = [
    {
        path: 'list',
        element: <MigrationListComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/options/migrations/list" />
    },
];