import React from "react";
import {
    Navigate,
} from "react-router-dom";

const UserListComponent = React.lazy(() => import("../../../../../components/users/components/list/page"));
const UserAddComponent = React.lazy(() => import("../../../../../components/users/components/add/page"));


export const usersRoute = [
    {
        path: 'list',
        element: <UserListComponent />,
    },
    {
        path: 'add',
        element: <UserAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/options/users/add" />
    },
    {
        path: 'edit/:id',
        element: <UserAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/options/users/list" />
    },
];