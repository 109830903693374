import React from "react";
import {
    Navigate,
} from "react-router-dom";

const SupplierListComponent = React.lazy(() => import("../../../../../components/supplier/components/list/page"));
const SupplierAddComponent = React.lazy(() => import("../../../../../components/supplier/components/add/page"));

export const supplierRoute = [
    {
        path: 'list',
        element: <SupplierListComponent />
    },
    {
        path: 'add',
        element: <SupplierAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/store/suppliers/add" />
    },
    {
        path: 'edit/:id',
        element: <SupplierAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/store/suppliers/list" />
    },
];