import { useQuery } from "@tanstack/react-query";
import { equipmentBrandApi } from "./axios";
import {
    ServiceResponseI,
    ProfileTaskI,
    TaskI,
    BrandI,
    EquipmentBrandI,
    EquipmentI,
    CatalogueI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



export const getAllBrandsByEquipmentReference = async (reference:string):Promise<ServiceResponseI<BrandI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('reference', reference);

        const { data } = await equipmentBrandApi.get<ServiceResponseI<BrandI[]>>(
            `/all-brands-by-equipment-reference`, 
            {    params,
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const getAllBrandsByEquipmentId = async (id:number):Promise<ServiceResponseI<BrandI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await equipmentBrandApi.get<ServiceResponseI<BrandI[]>>(
            `/all-brands-by-equipment-id/${id}`, 
            {    
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const getAllEquipmentsByBrandId = async (id:number):Promise<ServiceResponseI<CatalogueI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await equipmentBrandApi.get<ServiceResponseI<CatalogueI[]>>(
            `/all-equipments-by-brand-id/${id}`, 
            {   
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const getAllEquipmentBrandByEquipmentId = async (equipmentId:number):Promise<ServiceResponseI<EquipmentBrandI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await equipmentBrandApi.get<ServiceResponseI<EquipmentBrandI[]>>(
            `/all-by-equipment-id/${equipmentId}`, 
            {  
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentBrandPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentBrandApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentBrandPostByReference = async<T>(reference:string, getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('reference', reference);

        const { data } = await equipmentBrandApi.post<ServiceResponseI<string>>("/by-reference", getForms, { params, headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentBrandPut = async<T>(id: number, updateForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentBrandApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentBrandbyId = async (id: number): Promise<ServiceResponseI<ProfileTaskI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentBrandApi.get<ServiceResponseI<ProfileTaskI[]>>(`/all-by-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}
