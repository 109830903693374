import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {

    Backdrop,
    Box,
    CircularProgress,
    Grid,
    Grow,
    Paper,
    Typography
} from '@mui/material';
import {
    EQUIPMENT_AC_CODE,
    EQUIPMENT_BOARD_ELECTRICAL_CODE,
    EQUIPMENT_CAMS_CODE,
    EQUIPMENT_COMPUTER_CODE,
    EQUIPMENT_DOMOTICA_CODE,
    EQUIPMENT_MULTIMEDIA_CODE,
    EQUIPMENT_SECURITY_CODE,
    EQUIPMENT_UPS_CODE,
    EQUIPMENT_BOMBA_CODE,
    EQUIPMENT_KITCHEN_CODE,
    EQUIPMENT_SOLAR_CODE,
    EQUIPMENT_GENERATOR_CODE,
} from "../../libs/constants";
import { equipmentApi } from '../../libs/apis';
import { EquipmentI, ServiceResponseI } from '../../libs/interfaces';
import LogoKod from "../images/logo-kod-white.png";
import {
    ModalBodyAc,
    ModalBodyBoardElectrical,
    ModalBodyComputer,
} from "./components"


const GenericExteriorPage = () => {

    const params = useParams();
    let { "*": id } = params;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<EquipmentI | any>({} as EquipmentI)

    useEffect(() => {
        if (id) handleInformation();
    }, [])

    const handleInformation = async () => {
        try {
            const params = new URLSearchParams();
            id && params.append('id', id.toString());

            const { data } = await equipmentApi.get<ServiceResponseI<any>>(`/exterior`, { params });
            console.log(data)
            if (!data) return;
            setData(data.data!)
            setLoading(false);


        } catch (err: any) {
            console.log(err);
            setLoading(false);
            throw new Error(err);
        }
    }

    if (loading) return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )

    return (
        <Box sx={{ width: "100vw", height: "100vh", overflowX: "hidden", overflowY: "hidden" }}>

            <Paper sx={{
                p: 2,
                position: "relative",
                borderRadius: 0,
                maxHeight: `100vh`,
                minHeight: `100vh`,
                height: `100vh`,
                overflowY: "hidden",
                overflowX: "hidden",
            }}>
                <Box
                    sx={{
                        width: "100%",
                        position: 'absolute',
                        height: 80,
                        overflowY: 'hidden',
                        overflowX: 'hidden',
                        zIndex: 1101,
                        top: 0,
                        left: 0,
                        background: 'linear-gradient(90deg, #032d81,#8b9de5,#ffffff)',
                    }}
                >
                    <img src={LogoKod} style={{ width: 50, position: "absolute", top: 7, left: 5, zIndex: 1101 }} />
                    <Typography
                        variant="h3"
                        display="block"
                        gutterBottom
                        sx={{ position: "absolute", top: 9, left: 60, color: 'white', zIndex: 1101 }}
                    >
                        KOD
                    </Typography>
                    <Typography
                        variant="button"
                        display="block"
                        gutterBottom
                        sx={{ position: "absolute", top: 40.5, left: 61, color: 'white', fontWeight: "bolder", zIndex: 1101 }}
                    >
                        Control de Activos
                    </Typography>
                </Box>
                <Grid
                    container
                    justifyContent="center"
                    spacing={1}
                    direction="row"
                    sx={{
                        marginTop: 8,
                        maxHeight: `calc(100vh - 110px)`,
                        minHeight: `calc(100vh - 110px)`,
                        height: `calc(100vh - 30px)`,
                        overflowY: "auto",
                        overflowX: "auto",
                    }}>
                    {
                        data.typeEquipmentCode === EQUIPMENT_AC_CODE
                            ? <ModalBodyAc data={data} />
                            : data.typeEquipmentCode === EQUIPMENT_BOARD_ELECTRICAL_CODE
                                ? <ModalBodyBoardElectrical data={data} />
                                : data.typeEquipmentCode === EQUIPMENT_DOMOTICA_CODE
                                    ? <ModalBodyBoardElectrical data={data} />
                                    : data.typeEquipmentCode === EQUIPMENT_COMPUTER_CODE
                                        ? <ModalBodyAc data={data} />
                                        : data.typeEquipmentCode === EQUIPMENT_UPS_CODE
                                            ? <ModalBodyAc data={data} />
                                            : data.typeEquipmentCode === EQUIPMENT_SECURITY_CODE
                                                ? <ModalBodyAc data={data} />
                                                : data.typeEquipmentCode === EQUIPMENT_CAMS_CODE
                                                    ? <ModalBodyAc data={data} />
                                                    : data.typeEquipmentCode === EQUIPMENT_MULTIMEDIA_CODE
                                                        ? <ModalBodyAc data={data} />
                                                        : data.typeEquipmentCode === EQUIPMENT_SOLAR_CODE
                                                            ? <ModalBodyAc data={data} />
                                                            : data.typeEquipmentCode === EQUIPMENT_GENERATOR_CODE
                                                                ? <ModalBodyAc data={data} />
                                                                : data.typeEquipmentCode === EQUIPMENT_BOMBA_CODE
                                                                    ? <ModalBodyAc data={data} />
                                                                    : data.typeEquipmentCode === EQUIPMENT_KITCHEN_CODE
                                                                        ? <ModalBodyAc data={data} />
                                                                        : <ModalBodyAc data={data} />

                    }

                </Grid>
            </Paper>

        </Box>
    )
}

export default GenericExteriorPage;
