import React from "react";
import {
    Navigate,
} from "react-router-dom";

const BrandListComponent = React.lazy(() => import("../../../../../components/brands/components/list/page"));
const BrandAddComponent = React.lazy(() => import("../../../../../components/brands/components/add/page"));
const BrandAssignComponent = React.lazy(() => import("../../../../../components/brands/components/assign/page"));

export const brandRoute = [
    {
        path: 'list',
        element: <BrandListComponent />
    },
    {
        path: 'add',
        element: <BrandAddComponent />
    },
    {
        path: 'assign',
        element: <BrandAssignComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/store/brands/add" />
    },
    {
        path: 'edit/:id',
        element: <BrandAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/store/brands/list" />
    },
];