import React from 'react';
import { 
    CircularProgress, 
    Fab, 
    IconButton, 
    Tooltip 
} from '@mui/material';
import { 
    GetApp as GetAppIcon,
    PictureAsPdf as PictureAsPdfIcon, 
} from '@mui/icons-material'; 
import { Page, Text, View, Document, StyleSheet, Font, usePDF } from '@react-pdf/renderer'; 

export const PdfElement = ({data, headersColumn, HandleClickPdf, isSmall})=>{
    Font.register({family: 'Oswald',src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'});


    const styles = StyleSheet.create({
        containerTable:{
            width: 808.5, 
            marginLeft:17.5,
        },
        table: {    
            display: "table", 
            width:'100%',
            borderRightWidth: 0, 
            borderBottomWidth: 0,
        },
        tableRowHeader: {
            marginTop:10,
            flexDirection: "row" ,
            backgroundColor:'#0E3B5F',
            color:'#fff',
        }, 
        tableCellHeader:{
            fontSize: 7,
        },
        tableRow: {
            flexDirection: "row" ,
            backgroundColor:'#E5E8E8',
            height:20
        }, 
        tableCell: { 
            width:'100%',
        },
        
    });

    const MyDoc = (
        <Document>
            <Page size="A4" orientation="landscape" wrap>
                <View style={styles.containerTable} wrap>
                    <View style={styles.table} >
                        <View style={styles.tableRowHeader} fixed> 
                            {headersColumn.map((value, index)=>( 
                                <React.Fragment key={`headerRowPdf_${index}`}>
                                    {   value.show &&
                                        <View  style={{borderStyle:"solid", borderWidth:1, borderLeftWidth:0, borderTopWidth: 0, borderColor:'#fff', textAlign:value.align, display:'block', width:value.width, padding:'1px 0px'}} key={`theadcol${index}`}>
                                            <Text style={styles.tableCellHeader}> {value.label}</Text>
                                        </View>
                                    }
                                </React.Fragment>
                                    
                            ))}
                        </View>
                        {
                            data.map((value,index)=>( 
                                <View style={styles.tableRow} key={`rowPdf_${index}`}> 
                                    {
                                        headersColumn.map((value2,index2)=>( 
                                            <React.Fragment key={`rowPdf_${index}_${index2}`}>
                                                {   value2.show &&
                                                    <View style={{borderStyle: "solid",  borderWidth: 1,  borderLeftWidth: 0,  borderTopWidth: 0,width: value2.width, borderColor:'#fff', display:'block', fontSize:parseInt(value2.fontSize.split('p')[0]) - 3, fontWeight:value2.bolder ? 'bolder' :'none', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', textAlign:value2.align, padding:'1px 0px'}} key={`rows-${index}-${index2}`}>   
                                                        <Text style={styles.tableCell}>{value[value2.id] ? value[value2.id] : ''}</Text> 
                                                    </View>  
                                                }
                                            </React.Fragment>                                     
                                        ))
                                    }
                                </View>
                            ))
                        }
                    </View>
                </View>
            </Page>
        </Document>
    );

    const [ instance ] = usePDF({ document: MyDoc });

    if (instance.loading) return <CircularProgress size={isSmall ? 20 : 30}  />;

    if (instance.error){ 
        console.log(instance.error);
        return <div>Something went wrong: {instance.error}</div>;
    }

    if(isSmall){
        return (
            <Tooltip arrow title="Pdf" placement="top">
            <IconButton 
                color='primary' 
                href={instance.url} 
                onClick={HandleClickPdf} 
                size='small' 
                style={{ backgroundColor:'#ECF0F1', color:'red' }}
                target='_blank' 
                >
                <PictureAsPdfIcon />
            </IconButton>
            </Tooltip>
        );
    }else{
        return (
            <Fab 
                href={instance.url} 
                onClick={HandleClickPdf} 
                size='small' 
                color='primary' 
                target='_blank'
            >
                <GetAppIcon />
            </Fab>
        );
    }
};