import React from "react";
import {
  Navigate,
} from "react-router-dom";

const EquipmentListComponent = React.lazy(() => import("../../../components/equipments/components/list/page"));
const EquipmentAddComponent = React.lazy(() => import("../../../components/equipments/components/add/page"));
const EquipmentShowComponent = React.lazy(() => import("../../../components/equipments/components/show/page"));
const EquipmentAcAddComponent = React.lazy(() => import("../../../components/equipments/components/add-ac/page"));
const EquipmentKitchenAddComponent = React.lazy(() => import("../../../components/equipments/components/add-kitchen/page"));
const EquipmentBoardElectricalAddComponent = React.lazy(() => import("../../../components/equipments/components/add-board-electrical/page"));
const EquipmentDomoticaAddComponent = React.lazy(() => import("../../../components/equipments/components/add-domotica/page"));
const EquipmentCamsAddComponent = React.lazy(() => import("../../../components/equipments/components/add-cams/page"));
const EquipmentComputerAddComponent = React.lazy(() => import("../../../components/equipments/components/add-computer/page"));
const EquipmentGenericAddComponent = React.lazy(() => import("../../../components/equipments/components/add-generic/page"));
const EquipmentUpsAddComponent = React.lazy(() => import("../../../components/equipments/components/add-ups/page"));
const EquipmentSecurityAddComponent = React.lazy(() => import("../../../components/equipments/components/add-security/page"));
const EquipmentBombaAddComponent = React.lazy(() => import("../../../components/equipments/components/add-bomba/page"));
const EquipmentMultimediaAddComponent = React.lazy(() => import("../../../components/equipments/components/add-multimedia/page"));
const EquipmentGeneratorAddComponent = React.lazy(() => import("../../../components/equipments/components/add-generator/page"));
const EquipmentSolarAddComponent = React.lazy(() => import("../../../components/equipments/components/add-solar/page"));
const EquipmentDomoticaAloneAddComponent = React.lazy(() => import("../../../components/equipments/components/add-domotica-alone/page"));
// const EquipmentVehicleAddComponent = React.lazy(() => import("../../../components/equipments/components/add-vehicles/page"));

export const equipmentsRoute = [
  {
    path: 'ac',
    element: <EquipmentAcAddComponent />,
  },
  {
    path: 'add',
    element: <EquipmentAddComponent />,
  },
  {
    path: 'bomba',
    element: <EquipmentBombaAddComponent />,
  },
  {
    path: 'cams',
    element: <EquipmentCamsAddComponent />,
  },
  {
    path: 'computer',
    element: <EquipmentComputerAddComponent />,
  },
  {
    path: 'domotica',
    element: <EquipmentDomoticaAddComponent />,
  },
  {
    path: 'domotica-alone',
    element: <EquipmentDomoticaAloneAddComponent />,
  },
  {
    path: 'kitchen',
    element: <EquipmentKitchenAddComponent />,
  },
  {
    path: 'multimedia',
    element: <EquipmentMultimediaAddComponent />,
  },
  {
    path: 'security',
    element: <EquipmentSecurityAddComponent />,
  },
  {
    path: 'specials',
    element: <EquipmentGenericAddComponent />,
  },
  {
    path: 'ups',
    element: <EquipmentUpsAddComponent />,
  },
  {
    path: 'generator',
    element: <EquipmentGeneratorAddComponent />,
  },
  {
    path: 'solar',
    element: <EquipmentSolarAddComponent />,
  },
  {
    path: 'edit',
    element: <Navigate to="/equipments/add" />
  },
  {
    path: 'board-electrical',
    element: <EquipmentBoardElectricalAddComponent />,
  },
  {
    path: 'list',
    element: <EquipmentListComponent />,
  },
  {
    path: 'show',
    element: <EquipmentShowComponent />,
  },
  {
    path: 'ac/:id',
    element: <EquipmentAcAddComponent />
  },
  {
    path: 'bomba/:id',
    element: <EquipmentBombaAddComponent />,
  },
  {
    path: 'cams/:id',
    element: <EquipmentCamsAddComponent />
  },
  {
    path: 'edit/:id',
    element: <EquipmentAddComponent />
  },
  {
    path: 'board-electrical/:id',
    element: <EquipmentBoardElectricalAddComponent />
  },
  {
    path: 'computer/:id',
    element: <EquipmentComputerAddComponent />,
  },
  {
    path: 'domotica/:id',
    element: <EquipmentDomoticaAddComponent />,
  },
  {
    path: 'domotica-alone/:id',
    element: <EquipmentDomoticaAloneAddComponent />,
  },
  {
    path: 'kitchen/:id',
    element: <EquipmentKitchenAddComponent />,
  },
  {
    path: 'multimedia/:id',
    element: <EquipmentMultimediaAddComponent />,
  },
  {
    path: 'security/:id',
    element: <EquipmentSecurityAddComponent />,
  },
  {
    path: 'specials/:id',
    element: <EquipmentGenericAddComponent />,
  },
  {
    path: 'ups/:id',
    element: <EquipmentUpsAddComponent />,
  },
  {
    path: 'generator/:id',
    element: <EquipmentGeneratorAddComponent />,
  },
  {
    path: 'solar/:id',
    element: <EquipmentSolarAddComponent />,
  },
  // {
  //   path: 'vehicles/:id',
  //   element: <EquipmentVehicleAddComponent />,
  // },
  {
    path: 'list/:id',
    element: <EquipmentListComponent />,
  },
  {
    path: '',
    element: <Navigate replace to="/equipments/show" />
  },
]