import { FC } from 'react'
import { IconType } from "react-icons/lib";


import * as FaIcons from "react-icons/fa6";
import * as MdIcons from "react-icons/md";
import * as HiIcons from "react-icons/hi";
import * as CgIcons from "react-icons/cg";
import * as TbIcons from "react-icons/tb";
import * as AiIcons from "react-icons/ai";
import { MdOutlineDoNotDisturbOn } from "react-icons/md";
import { IDynamicIcon, IconMap, Library } from '../../interfaces';



export const DynamicIcon: FC<IDynamicIcon> = ({
    lib,
    icon,
    size = 16,
    color,
    style,
  }) => {
    const Icon: IconType = (returnLibraryIcons(lib) as IconMap)[icon];
  
    if(Icon){
        return (
            <Icon size={size} color={color ?? "#111"} style={style} />
          );
    }

    return <MdOutlineDoNotDisturbOn size={size} color={color ?? "#111"} style={style} />
    
  };
  
  const returnLibraryIcons = (lib: Library) => {
    const LibraryIcons = {
      fa: FaIcons,
      md: MdIcons,
      hi: HiIcons,
      ai: AiIcons,
      tb: TbIcons,
      cg: CgIcons,
    };
  
    return LibraryIcons[lib];
  };