import React from "react";
import {
  Navigate,
} from "react-router-dom";


const ClientsListComponent = React.lazy(() => import("../../../components/clients/components/list/page"));
const ClientsCreateComponent = React.lazy(() => import("../../../components/clients/components/add/create"));


export const clientsRoute = [
  {
    path: 'list',
    element: <ClientsListComponent />,
  },
  {
    path: 'add',
    element: <ClientsCreateComponent />,
  },
  {
    path: 'edit',
    element: <Navigate to="/clients/add" />
  },
  {
    path: 'edit/:id',
    element: <ClientsCreateComponent />
  },
  {
    path: '',
    element: <Navigate replace to="/clients/list" />
  },
]