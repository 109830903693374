import { useQuery } from "@tanstack/react-query";
import { clientAddressApi } from "./axios";
import {
    DataI,
    PropsI,
    ServiceResponseI,
    ClientAddressI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem,
} from '../../helpers'
import { AxiosError } from 'axios';

const getAllClientAddress = async ({
    pagination,
    sorting,
    globalFilter
}: PropsI): Promise<ServiceResponseI<DataI<ClientAddressI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));

        //headers: { 'Authorization': validateToken
        const { data } = await clientAddressApi.get<ServiceResponseI<DataI<ClientAddressI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            
            LogoutSystem();
        }

        throw new Error((err as Error).message)
    }


}

export const getAllAddressByClientId = async (id:number): Promise<ServiceResponseI<ClientAddressI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await clientAddressApi.get<ServiceResponseI<ClientAddressI[]>>(`/all-address-by-client-id/${id}`, { headers: { 'Authorization': validateToken } });

        return data

    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const clientAddressPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientAddressApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientAddressPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientAddressApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientAddressDelete = async (id: number): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientAddressApi.delete(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const clientAddressById = async (id: number): Promise<ServiceResponseI<ClientAddressI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await clientAddressApi.get<ServiceResponseI<ClientAddressI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const useClientAddress = ({
    sorting,
    pagination,
    globalFilter,
}: PropsI) => {


    const clientAddressQuery = useQuery(
        ["clientAddress", { pagination, sorting, globalFilter }],
        () => getAllClientAddress({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );






    return {
        clientAddressQuery,
    };
}