import { useQuery } from "@tanstack/react-query";
import { profileTaskApi } from "./axios";
import {
    ServiceResponseI,
    ProfileTaskI,
    TaskI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



export const getAllTasksByProfileId = async (profileId:string):Promise<ServiceResponseI<TaskI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('profile', profileId);

        const { data } = await profileTaskApi.get<ServiceResponseI<TaskI[]>>(
            `/all-tasks-by-profile-id`, 
            {    params,
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const validTaskCodeByToken = async (taskCode:string):Promise<ServiceResponseI<{status:boolean}>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('code', taskCode);

        const { data } = await profileTaskApi.get<ServiceResponseI<{status:boolean}>>(
            `/valid-task-code-by-token`, 
            {   
                params,
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}


export const profileTaskPost = async<T>(getForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await profileTaskApi.post("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const profileTaskPut = async<T>(id: number, updateForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await profileTaskApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const profileTaskbyId = async (id: number): Promise<ServiceResponseI<ProfileTaskI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await profileTaskApi.get<ServiceResponseI<ProfileTaskI[]>>(`/all-by-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}
