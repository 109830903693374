import React, { useState } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    CircularProgress,
    DialogTitle,
    IconButton
} from "@mui/material"
import { DialogProps } from '@mui/material/Dialog';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Radio, Button } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { COLOR_PRIMARY } from '../../../constants';
import CloseIcon from '@mui/icons-material/Close';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};


interface PropsReaderPdfI {
    title: string;
    open: boolean;
    onClose: () => void;
    file: any;
}



export const ReaderPdfComponent = ({
    title,
    open,
    onClose,
    file,
}: PropsReaderPdfI) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    const handleLeft = () => {
        setPageNumber((page) => { return Math.max(1, page - 1) })
    }

    const handleRight = () => {
        setPageNumber((page) => { return Math.min(page + 1, numPages!) })
    }



    return (
        <Dialog
            maxWidth="md"
            fullScreen
            open={open}
            onClose={onClose}
        >
            <BootstrapDialogTitle onClose={onClose} bg="transparent">
                <Radio.Group >
                    <Radio.Button value="-" onClick={handleLeft}  >
                        <LeftCircleOutlined />
                    </Radio.Button>
                    <Radio.Button value={title}>
                        {title}
                    </Radio.Button>
                    <Radio.Button value="+" onClick={handleRight}>
                        <RightCircleOutlined />
                    </Radio.Button>
                </Radio.Group>
                <Box sx={{ pl: 4, color: COLOR_PRIMARY }}>
                    Page {pageNumber} of {numPages}
                </Box>
            </BootstrapDialogTitle>
            <DialogContent sx={{
                    display:"flex", 
                    justifyContent:"center", 
                    alignItems:"center", 
                   }}>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options} loading={<CircularProgress size={40} />}>
                    <Page pageNumber={pageNumber} height={700}/>
                </Document>
            </DialogContent>
        </Dialog>
    )
}

interface PropsI {
    children:any;
    onClose:()=>void;
    bg?:string;
}
function BootstrapDialogTitle(props:PropsI) {
    const { children, onClose, bg, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, background :bg ?? "white", height:70, maxHeight:70, position:"absolute", width:"100vw", zIndex:1101 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "red",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
