import React from "react";
import {
    Navigate,
} from "react-router-dom";

const MaterialListComponent = React.lazy(() => import("../../../../../components/material/components/list/page"));
const MaterialAddComponent = React.lazy(() => import("../../../../../components/material/components/add/page"));

export const materialRoute = [
    {
        path: 'list',
        element: <MaterialListComponent />
    },
    {
        path: 'add',
        element: <MaterialAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/store/materials/add" />
    },
    {
        path: 'edit/:id',
        element: <MaterialAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/store/materials/list" />
    },
];