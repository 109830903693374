import React, { useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import {
    Image,
} from 'antd';
import {
    ConsumableI,
    EquipmentBoardElectricalI,
    EquipmentItemI,
    OtI,
} from '../../../../libs/interfaces';
import { MRT_ColumnDef, MRT_DensityState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useWidth } from '../../../../libs/hooks';
import noImage from "../../../../images/no-image.png";

export const EquipmentTableOts = ({
    data=[],
    height="410px"
}: { data: OtI[], height?:string }) => {

    const width = useWidth();
    const [density, setDensity] = useState<MRT_DensityState>("compact");
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: width === "xl" ? 12 : 10,
    });
    const [sorting, setSorting] = useState([
        { id: "order", desc: false },
    ])

    const columns = useMemo<MRT_ColumnDef<OtI>[]>(
        () => [
            {
                accessorKey: 'typeWork',
                header: 'Tipo de Trabajo',
                enableEditing: false,
                minSize: 150,
                size: 150,
            },
            {
                accessorKey: 'supplier',
                header: 'Proveedor',
                enableEditing: false,
                minSize: 150,
                size: 150,
            },
            {
                accessorKey: 'date',
                header: 'Fecha',
                enableEditing: false,
                minSize: 100,
                size: 120,
            },
            {
                accessorKey: 'observation',
                header: 'Observación',
                enableEditing: false,
                minSize: 150,
                size: 300,
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: data,
        createDisplayMode: 'row', // ('modal', and 'custom' are also available)
        editDisplayMode: 'cell', // ('modal', 'row', 'table', and 'custom' are also available)
        enableCellActions: true,
        enableClickToCopy: 'context-menu',
        enableColumnPinning: true,
        enableEditing: false,
        enableRowActions: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableDensityToggle: true,
        onDensityChange: setDensity,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getRowId: (row) => `${row.id}`,
        muiTableContainerProps: ({ table }) => {
            return {
                sx: {
                    minHeight: table.options.state.isFullScreen ? `calc(100vh - 100px)` : `calc(100vh - ${height})`,
                    maxHeight: table.options.state.isFullScreen ? `calc(100vh - 100px)` : `calc(100vh - ${height})`,
                    height: table.options.state.isFullScreen ? `calc(100vh - 100px)` : `calc(100vh - ${height})`, //500px
                }
            }
        },

        initialState: {
            density,
            pagination,
            sorting,
            columnPinning: {
                left: ['mrt-row-actions'],
            },
        },
        state: {
            density,
            pagination,
            sorting,
        },
    });
    return (
        <MaterialReactTable table={table} />
    )
}