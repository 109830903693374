import {
  Breakpoint,
  Theme,
  // ThemeProvider,
  useTheme,
  // createTheme,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// const  breakNew = "ab"  as string ;
export type newBreak = "xs" | "sm" | "md" | "lg" | "xl" | "ab";
type BreakpointOrNull = Breakpoint | null;

export const useWidth  = () =>  {
    const theme: Theme = useTheme(); 
    const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || 'xs'
    );
} 