import { lazy } from "react";
import {
    Navigate,
} from "react-router-dom";

const TaskListComponent = lazy(() => import("../../../../../components/tasks/components/list/page"));
const TaskAddComponent = lazy(() => import("../../../../../components/tasks/components/add/page"));

export const taskRoute = [
    {
        path: 'list',
        element: <TaskListComponent />
    },
    {
        path: 'add',
        element: <TaskAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/tasks/add" />
    },
    {
        path: 'edit/:id',
        element: <TaskAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/tasks/list" />
    },
];