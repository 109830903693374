import { useQuery } from "@tanstack/react-query";
import { materialsApi } from "./axios";
import {
    DataI,
    ServiceResponseI,
    MaterialI,
    PropsMaterialsI,
    CreateMaterialRequired,
    CreateMaterialI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



const getMaterials = async ({
    pagination,
    sorting,
    globalFilter,
    equipment,
    brand,
    group,
}: PropsMaterialsI): Promise<ServiceResponseI<DataI<MaterialI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));
        equipment && params.append('line', JSON.stringify(equipment));
        brand && params.append('group', JSON.stringify(brand));
        group && params.append('group', JSON.stringify(group));

        //headers: { 'Authorization': validateToken
        const { data } = await materialsApi.get<ServiceResponseI<DataI<MaterialI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }


}

export const materialPost = async (getForms: CreateMaterialRequired): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await materialsApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const materialPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await materialsApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message) 
    }
}

export const materialById = async (id: number): Promise<ServiceResponseI<MaterialI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await materialsApi.get<ServiceResponseI<MaterialI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const materialsByParentId = async (id:number):Promise<ServiceResponseI<MaterialI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await materialsApi.get<ServiceResponseI<MaterialI[]>>(`/${id}`);
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const materialsByParentMatchs = async (match:string):Promise<ServiceResponseI<{value:string}[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('match', match.toString());

        const { data } = await materialsApi.get<ServiceResponseI<{value:string}[]>>(`/childrens-by-match-parent`, {params, headers: { 'Authorization': validateToken }});
        
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const materialsByGroup= async (group:number):Promise<ServiceResponseI<MaterialI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await materialsApi.get<ServiceResponseI<MaterialI[]>>(`/all-by-group-id/${group}`, { headers: { 'Authorization': validateToken }});
        
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const getAllMaterialsLine = async ():Promise<ServiceResponseI<MaterialI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await materialsApi.get<ServiceResponseI<MaterialI[]>>(`/get-all-materials-line`, { headers: { 'Authorization': validateToken }});
        
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const getAllChildrenbyParent = async (id:number):Promise<ServiceResponseI<MaterialI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await materialsApi.get<ServiceResponseI<MaterialI[]>>(`/get-all-children-by-parent/${id}`, { headers: { 'Authorization': validateToken }});
        
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const getAllMaterialChildrenDistinctbyParent = async (id:number):Promise<ServiceResponseI<MaterialI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await materialsApi.get<ServiceResponseI<MaterialI[]>>(`/get-all-children-distinct-by-parent/${id}`, { headers: { 'Authorization': validateToken }});
        
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const getAllParents = async ():Promise<ServiceResponseI<MaterialI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await materialsApi.get<ServiceResponseI<MaterialI[]>>(`/get-all-parents`, { headers: { 'Authorization': validateToken }});
        
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
}

export const materialDelete = async<T>(id: number): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await materialsApi.delete(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const useMaterials = ({
    sorting,
    pagination,
    globalFilter,
    equipment,
    brand,
    group,
}: PropsMaterialsI) => {


    const materialQuery = useQuery(
        ["materials", { pagination, sorting, globalFilter, equipment, brand, group }],
        () => getMaterials({ pagination, sorting, globalFilter, equipment, brand, group }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );






    return {
        materialQuery,
    };
}