import { lazy } from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import { 
  ErrorPage,
  Login, 
  Outdoor,
  GenericPage,
} from "../../pages/";
import { Layout } from "../../root";
import { 
  clientsRoute,
  equipmentsRoute,
  optionsRoute,
  storeRoute,
  modelsRoute,
  userRoute,
  otsRoute,
  reportsRoute
} from "../routes";

const GenericExteriorPage = lazy(() => import("../../pages/lib/generic-exterior"));
const SupplierPage = lazy(() => import("../../pages/lib/supplier-page"));

export const router = createBrowserRouter([
  {
    path:"/login",
    element: <Login />,
  },
  {
    path:"/exterior",
    element: <Outdoor />,
    errorElement:<ErrorPage/>, 
    children:[
      {
        path: "equipment/*",
        element: <GenericExteriorPage />,
        errorElement:<ErrorPage/>,
      },
      {
        path:"supplier-ots",
        element: <SupplierPage />,
        errorElement:<ErrorPage/>,
      }
    ]
  },
  {
    path: "/",
    element: <Layout />,
    errorElement:<ErrorPage/>,
    children:[
      {
        path: "clients",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:clientsRoute, 
      },
      {
        path: "equipments",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:equipmentsRoute, 
      },
      {
        path: "ots",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:otsRoute, 
      },
      {
        path: "reports",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:reportsRoute, 
      },
      {
        path: "options",
        // element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:optionsRoute, 
      },
      {
        path: "store",
        // element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:storeRoute, 
      },
      {
        path: "models",
        // element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:modelsRoute, 
      },
      {
        path: "user",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:userRoute, 
      },
    ],
    
  },
  {
    path:"",
    element: <Login />,
  },
  
  

]);