import { lazy } from "react";
import {
    Navigate,
} from "react-router-dom";

const BankListComponent = lazy(() => import("../../../../../components/bank/components/list/page"));
const BankAddComponent = lazy(() => import("../../../../../components/bank/components/add/page"));

export const bankRoute = [
    {
        path: 'list',
        element: <BankListComponent />
    },
    {
        path: 'add',
        element: <BankAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/banks/add" />
    },
    {
        path: 'edit/:id',
        element: <BankAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/banks/list" />
    },
];