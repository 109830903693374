export enum Environments {
    CODE_PARAMETER_MAIL = 'MAIL',
    CODE_PARAMETER_HOST_EMAIL = 'HOST_MAIL',
    CODE_PARAMETER_PORT_EMAIL = 'PORT_MAIL',
    CODE_PARAMETER_USER_EMAIL = 'USER_MAIL',
    CODE_PARAMETER_PASS_EMAIL = 'PASSWORD_MAIL',
    CODE_PARAMETER_EMISOR_EMAIL = 'EMISOR_MAIL',
    PARAMETER_EMAIL_LOGO = 'MAIL_LOGO',
    LIMIT = 15,
    PAGE = 1,
    TIMEZONE_HOURS = 5,
    TIMEZONE_HOURS_MONGO = 0,
    TABLE_CATALOGUE = 'catalogue',
    TABLE_ANSWER = 'workflow_instance',
    TABLE_USER = 'user',
    TABLE_STATUS = 'status',
    TABLE_WORKFLOW = 'workflow',
    TABLE_MODULE = 'module',
    TABLE_ANSWER_MANAGER = 'workflow_manager',
    TABLE_ANSWER_STEP = 'workflow_instance_records',
    TABLE_TASK = 'task',
    TABLE_PARAMETER = 'parameter',
    TABLE_WORKFLOW_STEP = 'workflow_step',
    TABLE_ROLE_TASK = 'role_task',
    TABLE_ROLE = 'role',
    TABLE_USER_ROLE = 'user_role',
    TABLE_PERSON = 'person',
    FIELD_ANSWER = 'answer_id_783495',
    FIELD_DATE = 'created_at_785678',
    FIELD_STATUS = 'status_197382',
    FIELD_USER = 'user_create_325464',
    FIELD_STATUS_CODE = 'status_code_1244674',
    TYPE_CODE_DATE = 'DATE',
    TYPE_CODE_VARCHAR = 'VARCHAR',
    TYPE_CODE_INT = 'INT',
    TYPE_CODE_FK = 'FK',
    CONTROLS_TYPE = 'CONTROLS_TYPE',
    SUPERADMIN_CODE = 'SUPER_ADMIN',
    ADMIN_CODE = 'ADMINISTRATOR',
    EMPLOYEE_CODE = 'EMPLOYEE',
    AGENT_TECHNICAL_CODE = 'AGENT_TECHNICAL',
    LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
    DURATION_OTP = 100,
    ANSWER_DESIST = 'ANSWER_DESIST',
    ANSWER_CANCEL = 'ANSWER_CANCEL',
    MS_ADMIN = 'admin',
    MS_FORMS = 'forms',
    MS_SECURITY = 'security',
    MS_INTEGRATIONS = 'integrations',
    MS_FILES = 'file',
    MS_VUE = 'vue',
    MS_FINANCY = 'financy',
    RECOVERY_PASSWORD_CODE = 'RECOVER_PASSWORD',
    CHANGE_PASSWORD_CODE = 'CHANGE_PASSWORD',
    PROTOCOL_TASK = 'http',
    PROTOCOL_FILE = 'http',
    PROTOCOL_VUE = 'http',
    CREATE_TASK_PARAMS = 'CREATE_TASK',
    START_TASK_PARAMS = 'START_TASK',
    END_TASK_PARAMS = 'END_TASK',
    TYPE_USER_EXTERNO = 'EXTERNO',
    TYPE_USER_INTERNO = 'INTERNO',
    PARAMETER_PRIMARY_LOGO = 'PRIMARY_LOGO',
    PARAMETER_SECONDARY_LOGO = 'SECONDARY_LOGO',
    TASK_VALIDATE_TIME = 'TASK_TIME',
    TASK_VALIDATE_NOTIFY = 'TASK_NOTIFY',
    TASK_VALIDATE_RECTIFY = 'TASK_RECTIFY',
    PRIORITY_NEW = 'NEW',
    PRIORITY = 'PRIORITY',
    CODE_LOW = 'LOW',
    CODE_MEDIUM = 'MEDIUM',
    CODE_HIGHT = 'HIGHT',
    CODE_NEW = 'NEW',
    STATUS_DRAFT = 'DRAFT',
    INSTANCE_FOLDER = 'instance',
    CODE_MIPRO = 'MINPRO-0029',
    PATH_CERTIFICATE = 'certificate',
    RUC_ARCSA = 1111111111111,
    URL_CLIENT_CODE = 'URL_CLIENT',
    EMISSION_POINT_CODE = 'EMISSION_POINT',
    ESTABLISHMENT_NUMBER_CODE = 'ESTABLISHMENT_NUMBER',
    DEFAULT_EMISSION_TYPE = '1',
    DEFAULT_CUSTOMER_ID = '04',
    DEFAULT_NUMERIC_CODE = '00000000',
    DEFAULT_INVOICE_TIP = '0.00',
    DEAFULT_INVOICE_CURRENCY = 'DOLAR',
    MIN_SERIE_ACCESS_KEY_SRI = '2',
    MAX_SERIE_ACCESS_KEY_SRI = '7',
    SIGNED = 'FIRMADO',
    SIGNEDERROR = 'ERROR_FIRMA',
    ID_COMPROBANTE = 'comprobante',
    VERSION_COMPROBANTE = '1.0.0',
    ENVIRONMENT_PARENT_CODE = 'TIPO_AMBIENTE',
    ENVIRONMENT_INVOICE_CODE = 'AMBIENTE',
    PRD_SRI_CODE_QUERY = 'PRODUCCION_CONSULTA',
    PRD_SRI_CODE_AUTORIZATION = 'PRODUCCION_AUTORIZACION',
    SCHEMA_ANSWER = 'forms',
    SCHEMA_ADMIN = 'public',
    SCHEMA_SECURITY = 'security',
    NO_AUTORIZADO_CODE = 'NO_AUTORIZADO',
    AUTORIZADO_CODE = 'AUTORIZADO',
    EN_PROCESAMIENTO = 'EN_PROCESAMIENTO',
    ERROR_FIRMA_CODE = 'ERROR_FIRMA',
    SIGNED_CODE = 'FIRMADO',
    RECEPTED_CODE = 'RECEPCION',
    MAIL_LOGO_CODE = 'MAIL_LOGO',
    DEFAULT_IVA_VALUE = '0.12',
    RUC_COMPANY = 'RUC_COMPANY',
    COMPANY_RUC_CODE = "COMPANY_RUC_CODE",
    ERROR_RECEPCION_CODE = "ERROR_RECEPCION_CODE",
    SCHEMA_ANSWER_VUE = 'vue_gateway',
    FIELD_ANSWER_DCM_VUE = 'dcm_no',
    FIELD_ANSWER_REQ_VUE = 'req_no',
    FIELD_ANSWER_ESTABLISHMENT = 'establecimiento',
    FIELD_ANSWER_TYPE_COMPANY = 'cat_empresa',
    FIELD_ANSWER_ESTABLISHMENT_TYPE = 'tipo_establecimiento',
    FIELD_ANSWER_CHANGES_TYPE = 'tipo_de_cambio_bpm_gm_clv_ext'
  
  }
  