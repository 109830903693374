export class StringHelper {
    getslugFormat(text: string): string {
      let str = text.replace(/^\s+|\s+$/g, '');
  
      // Make the string lowercase
      str = str.toLowerCase();
  
      // Remove accents, swap ñ for n, etc
      const from =
        'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
      const to =
        'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
  
      // Remove invalid chars
      str = str
        .replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by -
        .replace(/\s+/g, '_')
        // Collapse dashes
        .replace(/-+/g, '_');
  
      return str;
    }
  
    replaceRecursive(input: string, search: string, replace: string): string {
      const index = input.indexOf(search);
      if (index === -1) {
        return input;
      } else {
        const before = input.substring(0, index);
        const after = input.substring(index + search.length);
        const replaced = this.replaceRecursive(after, search, replace);
        return before + replace + replaced;
      }
    }
  
    capitalizeFirstLetter(value: string) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
  