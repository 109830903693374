import React from "react";
import { GenericPage } from "../../../../../pages";


import { amperageRoute } from "./store-amperages.route";
import { brandRoute } from "./store-brands.route";
import { catalogueRoute } from "./store-catalogues.route";
import { equipmentRoute } from "./store-equipments.route";
import { materialRoute } from "./store-materials.route";
import { poloRoute } from "./store-polos.route";
import { supplierRoute } from "./store-suppliers.route";



export const storeRoute = [
  {
    path: 'amperages',
    element: <GenericPage />,
    children:amperageRoute,
  },
  {
    path: 'brands',
    element: <GenericPage />,
    children:brandRoute,
  },
  {
    path: 'catalogues',
    element: <GenericPage />,
    children:catalogueRoute,
  },
  {
    path: 'equipments',
    element: <GenericPage />,
    children:equipmentRoute,
  },
  {
    path: 'materials',
    element: <GenericPage />,
    children:materialRoute,
  },
  {
    path: 'polos',
    element: <GenericPage />,
    children:poloRoute,
  },
  {
    path: 'suppliers',
    element: <GenericPage />,
    children:supplierRoute,
  },
 
  
]