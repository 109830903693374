import { lazy } from "react";
import {
    Navigate,
} from "react-router-dom";

const OptionListComponent = lazy(() => import("../../../../../components/options/components/list/page"));
const OptionAddComponent = lazy(() => import("../../../../../components/options/components/add/page"));
const OptionAssignComponent = lazy(() => import("../../../../../components/options/components/assign/page"));

export const optionRoute = [
    {
        path: 'list',
        element: <OptionListComponent />
    },
    {
        path: 'add',
        element: <OptionAddComponent />
    },
    {
        path: 'assign',
        element: <OptionAssignComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/options/add" />
    },
    {
        path: 'edit/:id',
        element: <OptionAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/options/list" />
    },
];